<template>
    <v-app>
        <div v-if="!loader" class="pribg">
            <AppBar />

            <v-container  class="pt-0 px-0 px-md-3">
                <v-card class="mainbg pa-6 pt-3 pa-md-8 pt-md-4 elevation-0" light>
                    <v-main>
                        <v-expand-transition>
                            <router-view />
                        </v-expand-transition>
                    </v-main>
                </v-card>
            </v-container>
            
        </div>
        <div v-else class="pribg">
            <v-overlay  class="rounded-0" style="z-index:2000">
                <div class="text-center">
                    <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                </div>
            </v-overlay>
            
        </div>
    </v-app>
</template>

<style>
.v-application,
.v-application .display-1,
.v-application .headline,
.v-application .title,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .caption {
    font-family: 'Rajdhani' !important;
}

.pribg {
    height: 100%;
}

.v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
    margin: 0 !important;
}

.v-messages__message {
    line-height: 2 !important;
    font-size: 14px !important;
}

.v-application--is-ltr .v-text-field .v-label,
.v-input input {
    font-size: 16px !important;
    font-weight: 500 !important;
}

.v-main {
    padding-right: 0 !important;
}
</style>
<script>
import axios from 'axios'
import { apiurl } from '../../apiUrl.js'
import AppBar from '../navdraw/AppBar.vue';


export default {
    components: { AppBar },

  data: () => ({
    loader : true,
    minQuantity: '',
    maxQuantity: '',
    scriptsviews: false,
    viewtypebtn: true,
    diavlog: false,
    ordsrcto: true,
    marketstatus: false,
    fundAlart: false,
    snackbar: false,
    sheet: false,
    expand: false,
    orderbtnvis: true,
    gsec: [],
    nodata: false,
    nodataimg: false,
    gseciew: [],
    invesmentprice: '',
    qty: 100,
    cutOff: '',
    errMsg: '',
    dspinfo: [],
    clientId: '',
    session: '',
    orderResponce: [],
    endDate: "",
    startDate: "",
    totalLedger: 0,
    button: true,
    addAmt: '',
    gsecStartTime: 0,
    gsecEndTime: 0,
  }),

  methods: {
    
    redirectpages() {
      this.actid = localStorage.getItem("clientid");
      this.susertoken = localStorage.getItem("session");
      // this.actid = 'BL241';
      // this.susertoken = 'ad5aaf26fd75b2769ebc2e5e4df57a14a125b0804d79eaf602d4151f248bef6c';
      // this.susertoken = localStorage.getItem("session");
      // console.log('mosaaaaaaaaaaaaa', this.susertoken, this.actid);
      var dall = JSON.stringify({
        client_id: this.actid,
      });        // this.actid = localStorage.getItem("");
      let date1 = this.encryptionFunction(dall)
      // console.log(date1, "1saaaaaaaaaaaaa");
      // const axios = require('axios');
      let data = JSON.stringify({
        "string": date1
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://rekycbe.mynt.in/profile',
        headers: {
          'clientid': this.actid,
          'Authorization': this.susertoken,
          'Content-Type': 'application/json'
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          this.namedate = response.data.str
          let con = this.decryptionFunction(this.namedate)
          con = JSON.parse(con)
          con = con.client_data.CLIENT_NAME
          localStorage.setItem("cname", con);
            let cname = localStorage.getItem("clientid")
            if (cname) {
                axiosthis.loader = false
            }
        //   console.log("print 1");
          axiosthis.load = true

        })
        .catch((error) => {
          console.log(error);
        });

    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");
      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
    
    

  },

  computed: {
    gsecdspino() {
      return [
        { text: "symbol", value: this.dspinfo.symbol },
        { text: "series", value: this.dspinfo.series },
        { text: "isin", value: this.dspinfo.isin },
        { text: "issueSize", value: this.dspinfo.issueSize },
        { text: "faceValue", value: this.dspinfo.faceValue },
        { text: "maxPrice", value: this.dspinfo.maxPrice },
        { text: "minPrice", value: this.dspinfo.minPrice },
        { text: "minBidQuantity", value: this.dspinfo.minBidQuantity },
        { text: "maxQuantity", value: this.dspinfo.maxQuantity },
        { text: "cutOffPrice", value: this.dspinfo.cutoffPrice },
        { text: "lotSize", value: this.dspinfo.lotSize },
      ];
    },
  },
 
  mounted() {
    var axiosthis = this
    // var authcode = new URL(window.location.href).searchParams.get("authCode");



    // this.fundAlart = false
    this.clientId = localStorage.getItem("clientid")
    this.session = localStorage.getItem("session")
    // console.log("responclientIdse", this.clientId, this.session);

    if ((this.clientId == null) && (this.session == null)) {
      this.orderbtnvis = false;
      this.loader = false

    }
    var conffig = {
      method: 'post',
      url: `${apiurl}/getcurrentNCB_Gsecdetails`,
      headers: {}
    };

    axios(conffig)
      .then(function (response) {
        // console.log("gsec", response.data);
        axiosthis.gsec = response.data.NCBGsec
        if (response.data.NCBGsec) {
          axiosthis.gsecStartTime = response.data.NCBGsec[0].dailyStartTime.slice(0, 2);
          axiosthis.gsecEndTime = response.data.NCBGsec[0].dailyEndTime.slice(0, 2);
          axiosthis.gsecStartTime = Number(axiosthis.gsecStartTime);
          axiosthis.gsecEndTime = Number(axiosthis.gsecEndTime - 1);
          for (var i = 0; i < response.data.NCBGsec.length; i++) {
            // console.log("date for", response.data.NCBGsec[i].biddingEndDate);
            var endDate = new Date(response.data.NCBGsec[i].biddingEndDate);
            var startDate = response.data.NCBGsec[i].biddingStartDate;

            let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
              endDate
            );
            let mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(
              endDate
            );
            let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
              endDate
            );
            // console.log(`${da}-${mo}-${ye}`);
            axiosthis.endDate = `${da}-${mo}-${ye}`;
            axiosthis.startDate = startDate;
            // console.log("date for", axiosthis.endDate);

          }
        }

        if (response.data.msg == "no G-sec found") {
          axiosthis.errMsg = "Finding no G-SEC";
          axiosthis.nodataimg = true;
          axiosthis.viewtypebtn = false;

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async created() {
    
    var url = new URL(window.location.href); ''
    var sacc = url.searchParams.get("sAccountId");
    // var ucode = url.searchParams.get("ucode");
    var stok = url.searchParams.get("sToken");
    
    // if (ucode) {
    //   this.load = true
    //   console.log("1st con")
    // }
    // else 
    if (typeof sacc == 'string' && typeof stok == 'string') {
    //   console.log("2nd con")

      let datao = JSON.stringify({
        "token": stok,
        "LoginId": sacc
      });
      let configo = {
        method: 'post',
        url: 'https://rekycbe.mynt.in/autho/kambala_auth',
        headers: {
          'Content-Type': 'application/json'
        },
        data: datao
      };

      let axiosThis = this;
     await axios.request(configo)
        .then((response) => {
            // console.log(response.data.emsg,"ljljlljljl")
            
          if (response.data.clientid && response.data.token) {
            localStorage.setItem("session", response.data.token);
            localStorage.setItem("clientid", response.data.clientid);
            axiosThis.token = response.data.token;
            axiosThis.client_code = response.data.clientid;
            axiosThis.redirectpages();
          } else {
            localStorage.clear();
            axiosThis.loader = false

            // axiosThis.goSso();
          }
          window.location.assign(window.location.href.split('?')[0])

          // setTimeout(() => {
          // window.location.assign(window.location.href.split('?')[0])
          // }, 3000);
        })
        .catch((error) => {
          console.log(error);
          // axiosThis.goSso();
        });
    } else {
    //   console.log("3 con")

      let decoded = decodeURIComponent(window.location.search);
      var actid = decoded.split("&")[0].split("=")[1]
      var token = decoded.split("=")[2]
      this.token = localStorage.getItem("session");
      this.client_code = localStorage.getItem("clientid");
      // this.token = 'ad5aaf26fd75b2769ebc2e5e4df57a14a125b0804d79eaf602d4151f248bef6c';
      // this.client_code = 'BL241';
      if (typeof actid == 'string' && typeof token == 'string') {
        localStorage.setItem("session", token);
        localStorage.setItem("clientid", actid);
        this.token = localStorage.getItem("session");
        this.client_code = localStorage.getItem("clientid");
        window.location.assign(window.location.href.split('?')[0])
      }

      let data = JSON.stringify({
        "clientid": this.client_code,
        "token": this.token
      });

      let config = {
        method: 'post',
        url: 'https://rekycbe.mynt.in/autho/validate_session',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      let axiosThis = this;
      axios.request(config)
        .then((response) => {
          if (response.data.msg == "valid token") {
            // 
            axiosThis.redirectpages();

          } else if(response.data.emsg == 'invalid token' || response.data.emsg == 'token expired'){
            axiosThis.loader = false            
            localStorage.clear();

            // axiosThis.goSso();
          }
        })
        .catch((error) => {
          console.log(error);
          // axiosThis.goSso();
        });
    }




  },
  
}
</script>