<template>
  <div>
    <v-row no-gutters>
      <v-col cols="6">
        <v-row no-gutters>
          <img width="40px" class="mr-2" src="@/assets/ipo.svg" />
          <span class="title font-weight-bold black--text d-flex align-end">IPO</span>
        </v-row>
      </v-col>
      <v-col cols="6" class="d-flex flex-row-reverse">
        <div>
          <v-tooltip v-if="orderbtnvis" bottom color="transparent">
            <template v-slot:activator="{on, attrs}">
              <v-btn v-bind="attrs" v-on="on" to="/orderbookiposme" text active-class="blue--text text--accent-4" class="ordbokbtn font-weight-bold subtitle-1 elevation-0 mb-2 mr-4">
                <span class="d-none d-md-block">Order Book</span>
                <img width="20px" class="ml-md-1" src="@/assets/ordericon.svg" />
              </v-btn>
            </template>
            <v-card class="d-block d-md-none elevation-0 px-4 py-1" color="black">
              <span class="white--text">Order Book</span>
            </v-card>
          </v-tooltip>

          <v-tooltip v-if="viewtypebtn && $vuetify.breakpoint.mdAndUp" bottom color="black">
            <template v-slot:activator="{on, attrs}">
              <v-btn v-bind="attrs" v-on="on" @click="scriptsviews = !scriptsviews" active-class="blue--text text--accent-4" class="ordbokbtn elevation-0 px-0 mb-2">
                <v-icon v-if="scriptsviews == false">mdi-view-day</v-icon>
                <v-icon v-else>mdi-view-dashboard</v-icon>
              </v-btn>
            </template>
            <span v-if="scriptsviews == false">List view</span>
            <span v-else>Card view</span>
          </v-tooltip>

          <!-- <v-btn to="/orderbookiposme" text active-class="blue--text text--accent-4"
            class="ordbokbtn font-weight-bold subtitle-1 elevation-0 mb-2 mr-4">
            Order Book <img width="20px" class="ml-1" src="@/assets/ordericon.svg">
          </v-btn> -->
          <!-- <v-btn to="/holdingiposme" text active-class="blue--text text--accent-4"
            class="ordbokbtn font-weight-bold subtitle-1 elevation-0 mb-2">
            Holdings <img width="16px" class="ml-1" src="@/assets/holdingicon.svg">
          </v-btn> -->
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <p class="titmsg font-weight-medium">Initial public offering a new stock issuance for the first time.</p>

        <v-row v-if="scriptsviews">
          <v-col cols="12" v-for="(ipodata, i) in ipo" :key="i" class="shrink pb-0">
            <div>
              <v-card @click="inforsrc(ipodata)" class="elevation-0 rounded-lg mr-4" width="100%" color="#F9FBFC">
                <v-list-item>
                  <v-list-item-content class="pr-2 pr-md-4">
                    <v-list-item-title class="title font-weight-bold">{{ ipodata.symbol }} </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-bold">{{ ipodata.name }} </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">Opening </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold"
                      >{{ ipodata.biddingStartDate }} <span style="color: rgba(0, 0, 0, 0.6); font-size: 14px" class="font-weight-bold">({{ ipodata.dailyStartTime }})</span>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">Closing </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold"
                      >{{ ipodata.biddingEndDate.slice(4, 16) }} <span style="color: rgba(0, 0, 0, 0.6); font-size: 14px" class="font-weight-bold">({{ ipodata.dailyEndTime }})</span>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">Price Range </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold">₹{{ ipodata.minPrice }} - ₹{{ ipodata.maxPrice }} </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">LotSize </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold">{{ ipodata.lotSize }} </v-list-item-title>
                  </v-list-item-content>

                  <v-btn @click="investNow(ipodata)" color="blue" dense text class="font-weight-bold subtitle-1 elevation-0 custom-transform-class text-none"> Invest ₹{{ ipodata.maxPrice * ipodata.lotSize }} </v-btn>
                </v-list-item>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <!-- ---------------------------- -->
        <v-row v-else>
          <v-col cols="12" sm="6" md="4" xl="3" v-for="(ipodata, i) in ipo" :key="i">
            <div>
              <v-card @click="inforsrc(ipodata)" class="elevation-0 rounded-lg pb-2 mr-4" width="100%" color="#F9FBFC">
                <v-list-item>
                  <v-list-item-content class="pb-2">
                    <v-list-item-title class="title font-weight-bold">{{ ipodata.symbol }} </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-bold">{{ ipodata.name }} </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider class="mx-4"></v-divider>

                <v-list-item>
                  <v-list-item-content class="py-2">
                    <v-list-item-subtitle class="font-weight-bold">Opening </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold">{{ ipodata.biddingStartDate }} </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-bold">{{ ipodata.dailyStartTime }} </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-divider class="my-4" vertical></v-divider>
                  <v-list-item-content class="text-right py-2">
                    <v-list-item-subtitle class="font-weight-bold">Closing </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold">{{ ipodata.biddingEndDate.slice(4, 16) }} </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-bold">{{ ipodata.dailyEndTime }} </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider class="mx-4"></v-divider>

                <v-list-item>
                  <v-list-item-content class="pt-2 pb-0">
                    <v-list-item-subtitle class="font-weight-bold">Price Range </v-list-item-subtitle>
                    <v-list-item-subtitle class="font-weight-bold">LotSize </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="text-right pt-2 pb-0">
                    <v-list-item-title class="font-weight-bold">₹{{ ipodata.minPrice }} - ₹{{ ipodata.maxPrice }} </v-list-item-title>
                    <v-list-item-title class="font-weight-bold">{{ ipodata.lotSize }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <p class="text-center mb-0">
                  <v-btn @click="investNow(ipodata)" color="blue" dense text class="font-weight-bold subtitle-1 elevation-0 custom-transform-class text-none"> Invest ₹{{ ipodata.maxPrice * ipodata.lotSize }} </v-btn>
                </p>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-dialog v-model="diavlog" class="rounded-lg" max-width="520">
          <!-- <div v-for="(ipodata, i) in ipo" :key="i"> -->
          <v-card v-if="ordsrcto" class="rounded-lg">
            <v-toolbar color="" class="elevation-0" light outlined>
              <v-toolbar-title class="font-weight-bold">
                <v-list-item class="px-0 my-2">
                  <v-list-item-content>
                    <v-list-item-title class="title font-weight-bold">{{ ipoiew.name }} </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-bold">{{ ipoiew.symbol }} </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="diavlog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-progress-linear indeterminate v-if="loaders"></v-progress-linear>
            <div class="pb-4 px-8 pt-3">
              <v-alert class="mb-3" dense v-if="marketstatus" outlined type="warning" elevation="0">
                <p class="text-center mb-0 font-weight-medium">Market is not open</p>
              </v-alert>
              <v-row no-gutters>
                <v-col cols="6">
                  <p class="title font-weight-bold mb-0">
                    Bids <span>({{ addbitolen }}/3)</span>
                  </p>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <v-btn v-if="addbtnone" @click="oaddbit()" color="blue" dense text class="font-weight-bold subtitle-1 elevation-0 custom-transform-class text-none"> + Add </v-btn>
                  <v-btn v-if="addbtntwo" @click="taddbit()" :disabled="tbtnaddbit" color="blue" dense text class="font-weight-bold subtitle-1 elevation-0 custom-transform-class text-none"> + Add </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-autocomplete @change="showtolamtpay()" return-object item-text="val" item-value="key" required :rules="[(v) => !!v || 'Category is required']" v-model="catagory" :items="catagorys" dense label="Category" append-icon="mdi-chevron-down" outlined></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="pb-2" no-gutters>
                <v-col cols="6">
                  <span
                    >Lotsize : <span class="font-weight-bold">{{ ipoiew.lotSize }}</span></span
                  >
                </v-col>
                <v-col cols="5" class="bitstyle pl-4">
                  <span
                    >Price Range : <span class="font-weight-bold"> ₹{{ ipoiew.minPrice }} - ₹{{ ipoiew.maxPrice }}</span></span
                  >
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="5">
                  <v-text-field :step="iposteplot" @keyup="bitfuntol()" type="number" dense v-model="bitvarunit" :min="iposteplot" class="ma-0" label="Qty" outlined>
                    <template #append>
                      <v-btn @click="(bitvarunit = bitvarunit + iposteplot), bitfuntol()" icon class="elevation-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="#EEEEEE" />
                          <path d="M12 8V16" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                          <path d="M16 12L8 12" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </v-btn>
                    </template>
                    <template #prepend-inner>
                      <v-btn :disabled="bitvarunit == iposteplot" @click="bitvarunit == iposteplot ? bitvarunit : (bitvarunit = bitvarunit - iposteplot), bitfuntol()" icon class="elevation-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="#EEEEEE" />
                          <path d="M16 12L8 12" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="bitstyle pl-4">
                  <v-text-field :disabled="bitdisbtn" :filled="bitdisbtn" @keyup="bitfuntol()" outlined type="number" @change="bitprichg()" dense v-model="bitmodpri" :min="ipoiew.minPrice" :max="ipoiew.maxPrice" label="Price"> </v-text-field>
                  <v-checkbox dense @keyup="bitfuntol()" @click="bcheckbox()" v-model="bmodcheckbox" label="cutoff price"></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-if="bitone" no-gutters>
                <v-col cols="5">
                  <v-text-field :step="oiposteplot" @keyup="obitfuntol()" type="number" dense v-model="obitvarunit" :min="iposteplot" class="ma-0" label="Qty" outlined>
                    <template #append>
                      <v-btn @click="(obitvarunit = obitvarunit + iposteplot), obitfuntol()" icon class="elevation-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="#EEEEEE" />
                          <path d="M12 8V16" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                          <path d="M16 12L8 12" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </v-btn>
                    </template>
                    <template #prepend-inner>
                      <v-btn :disabled="obitvarunit == iposteplot" @click="obitvarunit == iposteplot ? obitvarunit : (obitvarunit = obitvarunit - iposteplot), obitfuntol()" icon class="elevation-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="#EEEEEE" />
                          <path d="M16 12L8 12" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="bitstyle pl-4">
                  <v-text-field :disabled="obitdisbtn" :filled="obitdisbtn" @keyup="obitfuntol()" outlined type="number" @change="obitprichg()" dense v-model="obitmodpri" :min="ipoiew.minPrice" :max="ipoiew.maxPrice" label="Price"> </v-text-field>
                  <v-checkbox dense @keyup="obitfuntol()" @click="obcheckbox()" v-model="obmodcheckbox" label="cutoff price"></v-checkbox>
                </v-col>
                <v-col cols="1" class="pt-0">
                  <v-btn icon @click="rmvbitone()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="bittwo" no-gutters>
                <v-col cols="5">
                  <v-text-field :step="tiposteplot" @keyup="tbitfuntol()" type="number" dense v-model="tbitvarunit" :min="iposteplot" class="ma-0" label="Qty" outlined>
                    <template #append>
                      <v-btn :disabled="(tbitvarunit = tbitvarunit)" @click="(tbitvarunit = tbitvarunit + iposteplot), tbitfuntol()" icon class="elevation-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="#EEEEEE" />
                          <path d="M12 8V16" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                          <path d="M16 12L8 12" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </v-btn>
                    </template>
                    <template #prepend-inner>
                      <v-btn :disabled="tbitvarunit == iposteplot" @click="tbitvarunit == iposteplot ? tbitvarunit : (tbitvarunit = tbitvarunit - iposteplot), tbitfuntol()" icon class="elevation-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="#EEEEEE" />
                          <path d="M16 12L8 12" stroke="#666666" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="bitstyle pl-4">
                  <v-text-field :disabled="tbitdisbtn" :filled="tbitdisbtn" @keyup="tbitfuntol()" outlined type="number" @change="tbitprichg()" dense v-model="tbitmodpri" :min="ipoiew.minPrice" :max="ipoiew.maxPrice" label="Price"> </v-text-field>
                  <v-checkbox dense @keyup="tbitfuntol()" @click="tbcheckbox()" v-model="tbmodcheckbox" label="cutoff price"></v-checkbox>
                </v-col>
                <v-col cols="1" class="pt-0">
                  <v-btn icon @click="rmvbittwo()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <p class="title font-weight-bold mb-0">UPI ID <span class="subtitle-1 black--text">(Virtual payment address)</span></p>
              <v-form ref="upiform" v-model="upivalid" lazy-validation>
                <v-row class="mt-0" no-gutters>
                  <v-col cols="12" class="upisty">
                    <v-text-field required :rules="upiidrule" v-model="upiId" dense class="ma-0" placeholder="upi id" outlined autofocus> </v-text-field>
                  </v-col>
                  <!-- <v-col cols="6" class="bitstyle pl-4">
                    <v-autocomplete required :rules="[v => !!v || 'Upi provider is required']" v-model="upiDomain"
                      :items="Upiitems" dense label="Upi provider" append-icon="mdi-chevron-down"
                      outlined></v-autocomplete>

                    <a href="#" style="text-decoration: none;">
                      <p class="subtitle-2 font-weight-medium blue--text text-right mt-1 mb-4">
                        <v-icon size="16" color="blue">mdi-information-outline</v-icon> UPI provider not
                        listed?
                      </p>
                    </a>
                  </v-col> -->
                </v-row>

                <v-list-item class="px-0">
                  <v-list-item-content class="pt-0">
                    <v-checkbox required :rules="[(v) => !!v || 'You must agree to invest!']" dense v-model="agreecheckbox" label="I hereby undertake that i have agree"></v-checkbox>
                  </v-list-item-content>
                  <!-- @keyup="tbitfuntol()" @click="tbcheckbox()" -->

                  <v-list-item-content class="text-right pt-0">
                    <v-list-item-title>
                      <v-btn :disabled="lmittolamtpay || !hideonmarketclose" text class="investbtn font-weight-medium text-capitalize" color="blue" @click="bfoPlaceorder()" :loading="loaders">
                        <p class="font-weight-bold title mb-0">
                          Invest ₹
                          <span>{{ tolamtpay.toFixed(2) }}</span>
                        </p>
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-alert v-if="alttolamt" dense outlined type="warning"> <span v-html="txtalttolamt"></span> </v-alert>
              </v-form>
            </div>
          </v-card>
          <v-card v-else class="rounded-lg">
            <v-toolbar color="" class="elevation-0" light outlined>
              <v-toolbar-title class="font-weight-bold"> Order details </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="(diavlog = false), (ordsrcto = true)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-progress-linear indeterminate color="blue" v-if="orderLoader"></v-progress-linear>
            <div class="pb-4 px-8">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="title font-weight-bold">{{ ipoiew.name }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-subtitle class="font-weight-medium mb-3"> OrderNumber </v-list-item-subtitle>
                  <v-list-item-subtitle class="font-weight-medium"> TotalAmountPayable </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium mb-3">
                    : <span>{{ orderRes.applicationNumber }}</span>
                  </v-list-item-title>
                  <v-list-item-title class="font-weight-medium">
                    : ₹
                    <span>{{ tolamtpay.toFixed(2) }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <p class="mb-1">
                Reason : <span>{{ orderRes.reason }}</span>
              </p>
              <span v-if="orderRes.status == 'success'" class="green--text font-weight-bold title">{{ orderRes.status }}</span>
              <h1 v-else class="red--text font-weight-bold title">
                {{ orderRes.status }}
              </h1>
            </div>
          </v-card>
          <!-- </div> -->
        </v-dialog>

        <v-card v-if="nodataimg" class="elevation-0 text-center mt-16 px-4">
          <img width="64px" src="@/assets/finding no.svg" alt="finding no data" />
          <p class="title font-weight-medium">{{ errMsg }}</p>
        </v-card>
      </v-col>

      <v-col class="shrink pl-3 d-none d-md-block">
        <v-expand-x-transition>
          <v-card class="elevation-0 rounded-lg pa-3" v-show="expand" width="300" color="#E3F2FD">
            <v-row no-gutters>
              <v-col cols="10"
                ><span class="title font-weight-bold black--text">{{ dspinfo.name }}</span></v-col
              >
              <v-col cols="2" class="d-flex flex-row-reverse">
                <v-btn icon @click="expand = false" class="font-weight-bold subtitle-1 mb-2">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="pb-2"></v-divider>
            <v-row v-for="(item, i) in ipodspino" :key="i" no-gutters>
              <v-col cols="6">
                <v-list-item-title class="subtitle-1 font-weight-bold">{{ item.text }} </v-list-item-title>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-list-item-subtitle class="subtitle-1 font-weight-medium">{{ item.value }} </v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-x-transition>
      </v-col>
    </v-row>

    <div class="d-md-none">
      <v-bottom-sheet class="d-md-none" hide-overlay v-model="sheet" inset>
        <v-sheet color="#F9FBFC" class="rounded-lg d-md-none" height="100%">
          <v-card class="elevation-0 pa-4 px-6" width="100%" color="transparent">
            <v-row no-gutters>
              <v-col cols="10"
                ><span class="title font-weight-bold black--text">{{ dspinfo.name }}</span></v-col
              >
              <v-col cols="2" class="d-flex flex-row-reverse">
                <v-btn icon @click="sheet = false" class="font-weight-bold subtitle-1 mb-2">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="pb-2"></v-divider>
            <v-row v-for="(item, i) in ipodspino" :key="i" no-gutters>
              <v-col cols="6">
                <v-list-item-title class="subtitle-1 font-weight-bold">{{ item.text }} </v-list-item-title>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-list-item-subtitle class="subtitle-1 font-weight-medium">{{ item.value }} </v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
    </div>
    <v-snackbar transition="slide-x-reverse-transition" v-model="errsnackbar" :timeout="5000" :value="true" absolute top color="red accent-2" text class="elevaation-0 title" right>
      <strong>{{ emsg }}</strong>
    </v-snackbar>
    <v-snackbar transition="slide-x-reverse-transition" v-model="snackbar" :timeout="5000" :value="true" absolute top color="warning" text class="elevaation-0 title" right>
      <v-icon color="warning">mdi-alert-outline</v-icon>
      Session <strong>expired</strong> Kindly Login again.
    </v-snackbar>
    <v-snackbar class="snakbar-sty rounded-pill" transition="slide-x-reverse-transition" v-model="snackbars" timeout="4000" :value="true" :color="snackcolor" absolute text-color="white">
      <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
      {{ snacktxt }}
      <v-icon @click="snackbars = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
    </v-snackbar>
  </div>
</template>

<style>
/*  */
</style>

<script>
import axios from "axios";
import {apiurl, getFundsupis, getUpivpa} from "../../apiUrl.js";

export default {
  data: () => ({
    snackbars: false,
    snacktxt: "",
    snackcolor: "#000",

    scriptsviews: false,
    viewtypebtn: true,
    diavlog: false,
    upiidrule: [
      (v) => !!v || "Upi Id is required",
      (v) => /.+@.+/.test(v) || "Upi Id must be valid",
      // v => /^[\w.-]+$/.test(v) || 'Upi Id must be valid',
    ],
    upivalid: true,
    upiId: "",
    upiDomain: null,
    agreecheckbox: false,
    alttolamt: false,
    txtalttolamt: "",
    iposteplot: 0,
    oiposteplot: 0,
    tiposteplot: 0,
    addbitolen: "1",
    tolamtpay: 0,

    bittotal: 0,
    bitmodlot: 0,
    bitmodpri: 0,
    bitvarunit: 0,
    bmodcheckbox: false,
    bitdisbtn: false,

    bitone: false,
    obittotal: 0,
    obitmodlot: 0,
    obitmodpri: 0,
    obitvarunit: "1",
    obmodcheckbox: false,
    obitdisbtn: false,
    addbtnone: true,

    bittwo: false,
    tbittotal: 0,
    tbitmodlot: 0,
    tbitmodpri: 0,
    tbitvarunit: "1",
    tbmodcheckbox: false,
    tbitdisbtn: false,
    addbtntwo: false,
    tbtnaddbit: false,

    sheet: false,
    expand: false,
    snackbar: false,
    lmittolamtpay: false,
    ordsrcto: true,
    marketstatus: false,
    hideonmarketclose: true,
    orderbtnvis: true,
    ipo: [],
    nodata: false,
    nodataimg: false,
    ipoiew: [],
    invesmentprice: "",
    cutOff: "",
    errMsg: "",
    dspinfo: [],
    orderResponce: [],
    biddingQty: "",
    orderRes: [],
    noOfBid: [],
    Upiitems: [
      "@allbank",
      "@aubank",
      "@axisbank",
      "@axl",
      "@barodampay",
      "@bandhan",
      "@citi",
      "@citigold",
      "@dbs",
      "@dlb",
      "@federal",
      "@freecharge",
      "@hsbc",
      "@icici",
      "@idbi",
      "@indus",
      "@ibl",
      "@kbl",
      "@kotak",
      "@okaxis",
      "@okhdfcbank",
      "@okicici",
      "@oksbi",
      "@paytm",
      "@rbl",
      "@sbi",
      "@sib",
      "@upi",
      "@uco",
      "@ybl",
      "@yesbank",
      "@pz",
      "@axisb",
      "@zoicici",
      "@wasbi",
      "@wahdfcbank",
      "@waaxis",
      "@waicici",
      "@idfcbank",
      "@timecosmos",
      "@tapicici",
      "@sliceaxis",
      "@shriramhdfcbank",
      "@pingpay",
      "@NIYOICICI",
      "@naviaxis",
      "@ikwik",
      "@goaxb",
      "@jupiteraxis",
      "@yesg",
      "@abfspay",
      "@abfspay",
      "@rapl",
      "@yapl",
      "@apl",
      "@slice",
      "@mbk",
      "@pockets",
      "@idfcpay",
      "@amazonpay",
      "@spicepay",
      "@omni",
      "@dhani",
      "@bpaywallet",
      "@trans",
      "@fam",
      "@pinelabs",
      "@digikhata",
      "@oxymoney",
    ],
    catagorys: [],
    catagory: {},
    orderLoader: true,
    ipoStartTime: 0,
    ipoEndTime: 0,
    emsg: "",
    errsnackbar: false,

    loaders: false,
  }),
  methods: {
    bitfuntol() {
      var myDate = new Date();
      var hrs = myDate.getHours();
      // this.bitvarunit = Math.ceil(this.bitvarunit / this.iposteplot) * this.iposteplot;
      if (hrs >= this.ipoStartTime && hrs <= this.ipoEndTime) {
        if (this.bitvarunit > this.ipoiew.lotSize) {
          setTimeout(() => {
            this.bitvarunit = Math.round(this.bitvarunit / this.iposteplot) * this.iposteplot;
          }, 2000);
        }
        this.bittotal = this.bitvarunit * this.bitmodpri;
        if (this.bitmodpri > this.ipoiew.maxPrice) {
          this.bitmodpri = this.ipoiew.maxPrice;
        }
        this.showtolamtpay();
      }
    },
    obitfuntol() {
      var myDate = new Date();
      var hrs = myDate.getHours();
      if (hrs >= this.ipoStartTime && hrs <= this.ipoEndTime) {
        if (this.obitvarunit > this.iposteplot) {
          setTimeout(() => {
            this.obitvarunit = Math.round(this.obitvarunit / this.iposteplot) * this.iposteplot;
          }, 2000);
        }
        this.obittotal = this.obitvarunit * this.obitmodpri;
        if (this.obitmodpri > this.ipoiew.maxPrice) {
          this.obitmodpri = this.ipoiew.maxPrice;
        }
        this.showtolamtpay();
      }
    },
    tbitfuntol() {
      var myDate = new Date();
      var hrs = myDate.getHours();
      if (hrs >= this.ipoStartTime && hrs <= this.ipoEndTime) {
        if (this.tbitvarunit > this.iposteplot) {
          setTimeout(() => {
            this.tbitvarunit = Math.round(this.tbitvarunit / this.iposteplot) * this.iposteplot;
          }, 2000);
        }
        this.tbittotal = this.tbitvarunit * this.tbitmodpri;
        if (this.tbitmodpri > this.ipoiew.maxPrice) {
          this.tbitmodpri = this.ipoiew.maxPrice;
        }
        this.showtolamtpay();
      }
    },
    showtolamtpay() {
      let maxis = this.catagory.data && this.catagory.data.maxUpiLimit && this.catagory.data.maxUpiLimit > 0 ? this.catagory.data.maxUpiLimit : 500000;

      var arr = [this.bittwo ? this.tbittotal : 0, this.bitone ? this.obittotal : 0, this.bittotal];
      this.tolamtpay = Math.max(...arr);

      if (this.bmodcheckbox == true) {
        if (this.tolamtpay > maxis) {
          this.lmittolamtpay = true;
          this.alttolamt = true;
          this.txtalttolamt = `On cutOff price Maximum investment upto <b>₹ ${maxis}</b> only`;
        } else {
          this.alttolamt = false;
          this.lmittolamtpay = false;
        }
      }
      if (this.obmodcheckbox == true) {
        if (this.tolamtpay > maxis) {
          this.lmittolamtpay = true;
          this.alttolamt = true;
          this.txtalttolamt = `On cutOff price Maximum investment upto <b>₹ ${maxis}</b> only`;
        } else {
          this.alttolamt = false;
          this.lmittolamtpay = false;
        }
      }
      if (this.tbmodcheckbox == true) {
        if (this.tolamtpay > maxis) {
          this.lmittolamtpay = true;
          this.alttolamt = true;
          this.txtalttolamt = `On cutOff price Maximum investment upto <b>₹ ${maxis}</b> only`;
        } else {
          this.alttolamt = false;
          this.lmittolamtpay = false;
        }
      } else if (this.tolamtpay > maxis) {
        this.lmittolamtpay = true;
        this.alttolamt = true;
        this.txtalttolamt = `Maximum investment upto <b>₹ ${maxis}</b> only`;
      } else {
        this.alttolamt = false;
        this.lmittolamtpay = false;
        // console.log('oh to invest 500k', this.tolamtpay)
      }
    },

    oaddbit() {
      // this.onOfBid.push({"activityType":"new","quantity":this.obitvarunit,"atCutOff": this.obmodcheckbox,"price": this.obitmodpri,"amount": this.obittotal});
      // console.log("11111111111", arreyofjson)
      // console.log("2nd arr", this.onOfBid)

      // console.warn("1", this.bitone, this.bittwo)
      if (this.bittwo == false) {
        this.bitone = true;
        this.addbitolen = "2";
        this.addbtnone = false;
        this.addbtntwo = true;
      }
      if (this.bitone == false) {
        this.bitone = true;
        this.addbitolen = "3";
        this.addbtnone = false;
        this.addbtntwo = true;
      }
    },
    taddbit() {
      this.onOfBid.push({activityType: "new", quantity: this.tbitvarunit, atCutOff: this.tbmodcheckbox, price: this.tbitmodpri, amount: this.tbittotal});
      // console.log("3nd arr", this.onOfBid)

      this.bittwo = true;
      this.addbitolen = "3";
      this.tbtnaddbit = true;
    },

    rmvbitone() {
      if (this.bitone == true) {
        this.addbtntwo = false;
        this.bitone = false;
        this.addbitolen = "2";
        this.addbtnone = true;
        this.obmodcheckbox = false;
        this.obitdisbtn = false;
        this.obitvarunit = this.ipoiew.lotSize;
      }
      if (this.bittwo == true) {
        this.bitone = false;
        this.addbitolen = "2";
        this.addbtntwo = false;
        this.obmodcheckbox = false;
        this.obitdisbtn = false;
        this.obitvarunit = this.ipoiew.lotSize;
      }
      if (this.bittwo == false) {
        this.bitone = false;
        this.addbitolen = "1";
        this.addbtnone = true;
        this.obmodcheckbox = false;
        this.obitdisbtn = false;
        this.obitvarunit = this.ipoiew.lotSize;
      }
      this.obitfuntol();
    },
    rmvbittwo() {
      if (this.bittwo == true) {
        this.bittwo = false;
        this.addbitolen = "2";
        this.addbtntwo = true;
        this.tbtnaddbit = false;
        this.tbmodcheckbox = false;
        this.tbitdisbtn = false;
        this.tbitvarunit = this.ipoiew.lotSize;
      }
      if (this.bitone == true) {
        this.bittwo = false;
        this.addbitolen = "2";
        this.tbtnaddbit = false;
        this.addbtntwo = true;
        this.tbmodcheckbox = false;
        this.tbitdisbtn = false;
        this.tbitvarunit = this.ipoiew.lotSize;
      }
      if (this.bitone == false) {
        this.addbtntwo = false;
        this.bittwo = false;
        this.addbitolen = "1";
        this.addbtnone = true;
        this.tbmodcheckbox = false;
        this.tbitdisbtn = false;
        this.tbitvarunit = this.ipoiew.lotSize;
      }
      this.tbitfuntol();
    },

    bitprichg() {
      if (this.bitmodpri < this.ipoiew.minPrice) {
        this.bitmodpri = this.ipoiew.minPrice;
      }
      if (this.bitmodpri > this.ipoiew.maxPrice) {
        this.bitmodpri = this.ipoiew.maxPrice;
      }
    },
    obitprichg() {
      if (this.obitmodpri < this.ipoiew.minPrice) {
        this.obitmodpri = this.ipoiew.minPrice;
      }
      if (this.obitmodpri > this.ipoiew.maxPrice) {
        this.obitmodpri = this.ipoiew.maxPrice;
      }
    },
    tbitprichg() {
      if (this.tbitmodpri < this.ipoiew.minPrice) {
        this.tbitmodpri = this.ipoiew.minPrice;
      }
      if (this.tbitmodpri > this.ipoiew.maxPrice) {
        this.tbitmodpri = this.ipoiew.maxPrice;
      }
    },

    bcheckbox() {
      if (this.bmodcheckbox == true) {
        this.bmodcheckbox = true;
        this.bitmodpri = this.ipoiew.maxPrice;
        this.bitdisbtn = true;
      }
      if (this.bmodcheckbox == false) {
        this.bmodcheckbox = false;
        this.bitmodpri = this.ipoiew.minPrice;
        this.bitdisbtn = false;
      }
      this.bitfuntol();
      this.showtolamtpay();
    },
    obcheckbox() {
      if (this.obmodcheckbox == true) {
        this.obmodcheckbox = true;
        this.obitmodpri = this.ipoiew.maxPrice;
        this.obitdisbtn = true;
      }
      if (this.obmodcheckbox == false) {
        this.obmodcheckbox = false;
        this.obitmodpri = this.ipoiew.minPrice;
        this.obitdisbtn = false;
      }
      this.obitfuntol();
      this.showtolamtpay();
    },
    tbcheckbox() {
      if (this.tbmodcheckbox == true) {
        this.tbmodcheckbox = true;
        this.tbitmodpri = this.ipoiew.maxPrice;
        this.tbitdisbtn = true;
      }
      if (this.tbmodcheckbox == false) {
        this.tbmodcheckbox = false;
        this.tbitmodpri = this.ipoiew.minPrice;
        this.tbitdisbtn = false;
      }
      this.tbitfuntol();
      this.showtolamtpay();
    },

    inforsrc(det) {
      this.expand = true;
      this.sheet = true;
      this.dspinfo = det;
      // console.log("ipoindata", det)
    },
    async investNow(data) {
      if (this.clientId == null && this.session == null) {
        this.diavlog = false;
        window.location.assign(`https://desk.mynt.in/?url=${window.location.href}`);
        // console.log("none")
      } else {
        this.catagorys = [];
        for (var q = 0; q < data.subCategorySettings.length; q++) {
          // IND - Individual
          // EMP - Employee
          // SHA - Shareholder
          // POL - Policyholder
          if (data.subCategorySettings[q].allowUpi) {
            if (data.subCategorySettings[q].subCatCode == "IND") {
              this.catagorys.push({key: "IND", val: "Individual", data: data.subCategorySettings[q]});
            }
            if (data.subCategorySettings[q].subCatCode == "EMP") {
              this.catagorys.push({key: "EMP", val: "Employee", data: data.subCategorySettings[q]});
            }
            if (data.subCategorySettings[q].subCatCode == "SHA") {
              this.catagorys.push({key: "SHA", val: "Shareholder", data: data.subCategorySettings[q]});
            }
            if (data.subCategorySettings[q].subCatCode == "POL") {
              this.catagorys.push({key: "POL", val: "Policyholder", data: data.subCategorySettings[q]});
            }
            this.catagory = this.catagorys[0];
          }
        }
        var myDate = new Date();
        var hrs = myDate.getHours();
        // var hrs = 18;
         if (hrs >= this.ipoStartTime && hrs <= this.ipoEndTime) {
          this.marketstatus = false;
          this.hideonmarketclose = true;
          this.lmittolamtpay = false;
        } else {
          this.marketstatus = true;
          this.hideonmarketclose = false;
          this.lmittolamtpay = true;
        }
        let upis = await getFundsupis(this.clientId);
        if (upis.stat == "Ok" && upis.data[0] && upis.data[0].upi_id && upis.data[0].client_id == this.clientId) {
          this.upiId = upis.data[0].upi_id;
        } else {
          this.upiId = "";
        }
        this.loaders = false;
        this.upivalid = true;
        this.upiDomain = null;
        this.agreecheckbox = false;
        this.alttolamt = false;
        this.tolamtpay = 0;
        this.addbitolen = "1";

        this.bittotal = 0;
        this.bitmodlot = 0;
        this.bitmodpri = 0;
        this.bmodcheckbox = true;
        this.bitdisbtn = false;

        this.bitone = false;
        this.obittotal = 0;
        this.obitmodlot = 0;
        this.obitmodpri = 0;
        this.obmodcheckbox = true;
        this.obitdisbtn = false;
        this.addbtnone = true;

        this.bittwo = false;
        this.tbittotal = 0;
        this.tbitmodlot = 0;
        this.tbitmodpri = 0;
        this.tbmodcheckbox = true;
        this.tbitdisbtn = false;
        this.addbtntwo = false;
        this.tbtnaddbit = false;

        this.ipoiew = data;
        this.cutOff = data.cutoffPrice;
        this.bitmodpri = this.ipoiew.maxPrice;
        this.obitmodpri = this.ipoiew.maxPrice;
        this.tbitmodpri = this.ipoiew.maxPrice;

        this.bitmodlot, this.bitvarunit, (this.iposteplot = this.ipoiew.lotSize);
        this.obitmodlot, this.obitvarunit, (this.oiposteplot = this.ipoiew.lotSize);
        this.tbitmodlot, this.tbitvarunit, (this.tiposteplot = this.ipoiew.lotSize);

        this.bitvarunit = this.ipoiew.lotSize;
        this.obitvarunit = this.ipoiew.lotSize;
        this.tbitvarunit = this.ipoiew.lotSize;

        // console.log("ipoiew", data)
          this.bitfuntol();
          this.obitfuntol();
          this.tbitfuntol();

          this.bcheckbox();
          this.tbcheckbox();
          this.tbcheckbox();
          this.onOfBid = [{activityType: "new", quantity: this.bitvarunit, atCutOff: this.bmodcheckbox, price: this.bitmodpri, amount: this.bittotal}];
          this.diavlog = true;
          this.ordsrcto = true;
      }
    },
    async bfoPlaceorder() {
      if (this.$refs[`upiform`].validate()) {
        this.loaders = true;
        let data = await getUpivpa([this.upiId, this.clientId, this.session]);
        if (this.upiId == data.data.client_VPA && data.data.verified_VPA_status1 == "Available") {
          this.applyNow();
        } else {
          // this.upiId = "";
          this.snackbars = true;
          this.snacktxt = "Invalid UPI ID";
          this.snackcolor = "error";
          this.loaders = false;
        }
      }
    },
    applyNow() {
      this.diavlog = true;
      this.ordsrcto = false;
      var axiosthis = this;
      var datav = {
        symbol: this.ipoiew.symbol,
        UPI: this.upiId,
        category: this.catagory.key,
        type: this.ipoiew.type,
        BID: [{activityType: "new", quantity: this.bitvarunit, atCutOff: this.bmodcheckbox, price: this.bitmodpri, amount: this.bittotal}],
        // [
        //   {
        //     "activityType": "new",
        //     "quantity": this.biddingQty,
        //     "atCutOff": this.obmodcheckbox,
        //     "price": this.obitmodpri,
        //     "amount": this.bittotal
        //   }
        // ]
      };
      if (this.bitone) {
        datav.BID.push({activityType: "new", quantity: this.obitvarunit, atCutOff: this.obmodcheckbox, price: this.obitmodpri, amount: this.obittotal});
      }
      if (this.bittwo) {
        datav.BID.push({activityType: "new", quantity: this.tbitvarunit, atCutOff: this.bmodcheckbox, price: this.tbitmodpri, amount: this.tbittotal});
      }
      var config = {
        method: "post",
        url: `${apiurl}/addIPOtoPortfolio`,
        headers: {
          "Content-Type": "application/json",
          clientid: axiosthis.clientId,
          Authorization: axiosthis.session,
        },
        data: JSON.stringify(datav),
      };
      // console.log("config", config);

      axios(config)
        .then(function (response) {
          axiosthis.loaders = false;
          if (response.data.response) {
            axiosthis.orderRes = response.data.response;
            // console.log(axiosthis.orderRes);
          } else {
            axiosthis.emsg = response.data.msg;
            axiosthis.errsnackbar = true;
          }
          if (response.data.emsg == "Authorization Required" || response.data.emsg == "token expired" || response.data.emsg == "invalid token") {
            window.location.assign(`https://desk.mynt.in/?url=${window.location.href}`);
          }
          if (response.data.emsg == "invalid token") {
            axiosthis.snackbar = true;
            console.warn("Hs");
            localStorage.removeItem("clientid");
            localStorage.removeItem("session");
            localStorage.removeItem("cname");
            setTimeout(function () {
              axiosthis.$router.push("/gsec");
              window.location.reload();
            }, 2000);
          }
          axiosthis.orderLoader = false;
        })
        .catch(function (error) {
          console.log(error);
          axiosthis.orderLoader = false;
          axiosthis.loaders = false;
        });
    },
  },
  computed: {
    ipodspino() {
      return [
        {text: "symbol", value: this.dspinfo.symbol},
        {text: "isin", value: this.dspinfo.isin},
        {text: "issueType", value: this.dspinfo.issueType},
        {text: "issueSize", value: this.dspinfo.issueSize},
        {text: "lotSize", value: this.dspinfo.lotSize},
        // { text: "biddingStartDate", value: this.dspinfo.biddingStartDate },
        // { text: "dailyStartTime", value: this.dspinfo.dailyStartTime },
        // { text: "biddingEndDate", value: this.dspinfo.biddingEndDate },
        // { text: "dailyEndTime", value: this.dspinfo.dailyEndTime },
        {text: "faceValue", value: this.dspinfo.faceValue},
        {text: "maxPrice", value: this.dspinfo.maxPrice},
        {text: "minPrice", value: this.dspinfo.minPrice},
        {text: "cutOffPrice", value: this.dspinfo.cutOffPrice},
        {text: "minBidQuantity", value: this.dspinfo.minBidQuantity},
      ];
    },
  },

  mounted() {
    this.clientId = localStorage.getItem("clientid");
    this.session = localStorage.getItem("session");
    if (this.clientId == null && this.session == null) {
      this.orderbtnvis = false;
    }
    var axiosthis = this;
    var conffig = {
      method: "post",
      url: `${apiurl}/getcurrentIPOdetails`,
      headers: {},
    };

    axios(conffig)
      .then(function (response) {
        // console.log("ipo", response.data);
        axiosthis.ipo = response.data.MainIPO;
        if (response.data.MainIPO) {
          axiosthis.ipoStartTime = response.data.MainIPO[0].dailyStartTime.slice(0, 2);
          axiosthis.ipoEndTime = response.data.MainIPO[0].dailyEndTime.slice(0, 2);
          axiosthis.ipoStartTime = Number(axiosthis.ipoStartTime);
          axiosthis.ipoEndTime = Number(axiosthis.ipoEndTime - 1);
          for (var i = 0; i < response.data.MainIPO.length; i++) {
            // console.log("date for", response.data.MainIPO[i].biddingEndDate);
            var endDate = new Date(response.data.MainIPO[i].biddingEndDate);
            var startDate = response.data.MainIPO[i].biddingStartDate;

            let ye = new Intl.DateTimeFormat("en", {year: "numeric"}).format(endDate);
            let mo = new Intl.DateTimeFormat("en", {month: "numeric"}).format(endDate);
            let da = new Intl.DateTimeFormat("en", {day: "2-digit"}).format(endDate);
            // console.log(`${da}-${mo}-${ye}`);
            axiosthis.endDate = `${da}-${mo}-${ye}`;
            axiosthis.startDate = startDate;
          }
        }
        //   let d = new Date(2010, 7, 5);
        if (response.data.msg == "no IPO found") {
          axiosthis.errMsg = "Finding no IPO";
          axiosthis.nodataimg = true;
          axiosthis.viewtypebtn = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<style>
.snakbar-sty {
  z-index: 203 !important;
  position: fixed !important;
}
</style>
