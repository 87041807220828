export const apiurl = "https://besim.zebull.in";
export const ledapi = "https://rekycbe.mynt.in";

// export const apiurl="http://192.168.5.81:9006";
// export const apiurl="http://192.168.5.74:5010";
// export const appurl="https://in.zebull.in";
// export const appurl="http://192.168.5.195:8081";

const upiurl = "https://fundapi.mynt.in/";


let myHeaders = new Headers();
var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    headers: myHeaders
};
myHeaders.append("Content-Type", "application/json");

export async function getFundsupis(item) {
    requestOptions['body'] = `{"client_id":"${item}"}`;
    var response = await fetchMyntAPI(upiurl + "withdraw/view_upi_id", requestOptions);
    return response
}

export async function getUpivpa(item) {
    requestOptions['body'] = `{"VPA":"${item[0]}","clientID":"123456789","bank_acc":"123456789"}`;
    myHeaders.append("clientid", `${item[1]}`);
    myHeaders.append("Authorization", `${item[2]}`);
    var response = await fetchMyntAPI(upiurl + "hdfc/upi/checkClientVPA", requestOptions);
    myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    return response
}

async function fetchMyntAPI(path, requestOptions) {
    try {
        const response = await fetch(path, requestOptions);
        let data = await response.json();
        data = JSON.stringify(data);
        data = JSON.parse(data);
        return data
    } catch (error) {
        let data = 500
        return data
    }
}