<template>
    <div class="signupbgclr overflow-hidden">
      <v-container fill-height class="SsoView overflow-hidden">
        <v-card max-width="320px" height="80vh" class="mx-auto my-auto elevation-0 py-8 pb-1 rounded-lg text-center">
          <div class="px-md-8">
            <img src="@/assets/logo.svg" width="30%">
            <!-- <v-card-subtitle class="body-1"> -->
              <!-- Track all of your data from anywhere, at any time. -->
            <!-- </v-card-subtitle> -->
            <!-- <img src="@/assets/Group 277.svg" class="mt-2 mb-6" width="100%"> -->
            <v-card width="400px" class="mx-auto px-6 elevation-0 text-center">
            <!-- <img width="150" src="https://www.zebuetrade.com/static/logo/Logo.svg" /> -->
            <h3 class="pa-6">Sign-in with zebu</h3>
            <v-form
            v-if="inputForm"
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="validate()"
    >
    <v-text-field
      v-model="userId"
      :rules="userIdRules"
      autofocus
      class="uid"
      label="User Id"
      required
      @keyup="uppercase()"
      outlined
      dense
    ></v-text-field>
  
  
  
    <v-btn
      :disabled="!valid"
      :loading="btnloading"
      block
      class="btn my-2 rounded-md"
      color="primary"
      type="submit"    ><b>
      Submit</b>
    </v-btn>
  </v-form>
  <!-- <div v-if="otpInput"> -->
    <v-form v-if="otpInput" ref="formm" v-model="validd" lazy-validation @submit.prevent="validateotp()">
        <p>Enter the 4-digit OTP received on your registered email or mobile</p>

  <v-otp-input
  v-model="otp" @change="validateotp" type="number"
    :length="length" dense 
     autofocus
    
></v-otp-input>
<v-btn
:disabled="!isActive"
      :loading="btnloadingg"
      class="btn my-2 rounded-md"
      block      
      color="primary"
      type="submit">
      Submit
    </v-btn>
</v-form>
<!-- </div> -->
        </v-card>
        <v-snackbar
        style="z-index: 2 !important; margin-bottom: 20px;"
        transition="slide-y-reverse-transition"
        v-model="snackbar"
        :timeout="3000"
        :value="true"
        color="blue-grey darken-3"
        absolute
        bottom
        center
      >
        <v-icon color="warning">mdi-alert-outline</v-icon>
        {{ errmsg }}
      </v-snackbar>
            <v-card-subtitle style="font-size:11px;" class="text-center">
              <p style="color:#858B95;">By continuing with ZEBU, you agree our Terms and Privacy Policy
              </p>
            </v-card-subtitle>
          </div>
        </v-card>
      </v-container>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
import { apiurl } from '../../apiUrl.js'

export default {
  data: () => ({
    show1: false,
    btnloadingg: false,
    btnloading: false,
    errmsg: "",
    snackbar: false,
    valid: true,
    validd: true,

    length: 4,
        otp:'',
    inputForm: true,
    otpInput: false,
    userId: "",
    userIdRules: [(v) => !!v || "User ID is required"],
    otpRules: [(v) => !!v || "OTP is required"],
    password: "",
    passwordRules: [
      (v) => !!v || "Your mobile number is required",
      (v) => /^[6-9]\d{9}$/.test(v) || "mobile number must be 10 Digit Number",
    ],
  }),
  computed: {
      isActive () {
        return this.otp.length === this.length
      },
    },
  methods: {
    uppercase() {
      this.userId = this.userId.toUpperCase();
    },
    validate() {
      this.$refs.form.validate();
      var axiosthis = this
    //   console.log("validate", this.$refs.form.validate());
      if (this.$refs.form.validate()) {
      this.btnloading = true

        var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiurl}/client_check?code=`+this.userId,
        headers: { },
        };

        axios(config)
        .then(function (response) {
        // console.log(JSON.stringify(response.data.res));
        if(response.data.res == 'Invalid Client Code') {
            axiosthis.snackbar = true
            axiosthis.errmsg = "Invalid Client Code"
            axiosthis.btnloading = false
        } else {
            axiosthis.inputForm  = false
            axiosthis.otpInput = true
            axiosthis.btnloading = false

        }
        })
        .catch(function (error) {
        console.log(error);
        axiosthis.btnloading = false
        });

      }
    },
    validateotp() {
      this.$refs.formm.validate();
      var validateInput = this.$refs.formm.validate();
    //   console.log("validate", this.$refs.formm.validate());

    if(validateInput){  
        // if (this.$refs.formm.validate()) {
        this.btnloadingg = true; 
        // console.log("papa",this.userId,this.otp)
        var axiosthis = this
                    var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${apiurl}/otp_verify?code=`+this.userId+'&otp='+this.otp,
            headers: { }
            };

            axios(config)
            .then(function (response) {
            // console.log(response.data);
            if (response.data.res == 'Invalid otp') {
                axiosthis.snackbar = true
                axiosthis.errmsg = "Invalid OTP"
                axiosthis.btnloadingg = false
            } else {
                axiosthis.$router.push('/')
                localStorage.setItem("clientid",response.data.clientid)
                localStorage.setItem("cname",response.data.name)
                localStorage.setItem("session",response.data.clientsession)
            }
            
            })
            .catch(function (error) {
            console.log(error);
            });

        //  }
        }
    }
  },

  mounted() {
    localStorage.clear();
  },
};
</script>
<style>

/* .v-application--is-ltr .v-text-field .v-label, .v-input input {
   color: red !important;
  opacity: 1 !important; 
  font-weight: 200 !important;
} */
 .v-text-field--outlined fieldset {
    border-color: rgb(185, 185, 185) !important
  }

.v-text-field__details {
    padding: 0;
}
::-webkit-scrollbar {
  width: 6px;
}

.btn {
  box-shadow: 0px 8px 16px 2px rgba(22, 34, 51, 0.08) !important;
}

.signupbgclr {
  background-color: #ECEEF0 !important;
  height: 100vh !important;
  overflow-y: hidden !important;
}

.SsoView {
  height: 100vh !important;
  overflow-y: hidden !important;
}

@media only screen and (max-width: 600px) {
  .signupbgclr {
    background-color: white !important;
  }

  .btn:hover {
    background-color: #ECEEF0 !important;
  }
}
</style>