<template>
  <div>
    <v-app-bar style="background-color: white; position: fixed;" absolute width="100%" permanent app
      class="px-0 px-md-8 elevation-0" outlined>
      <img src="@/assets/logo.svg" width="90px" />

      <!-- <div id="desktopmenubar"> -->
      <v-row class="d-none d-md-flex flex-md-row-reverse mr-0 mr-md-4">
        <v-list nav color="transparent">
          <v-list-item-group v-model="selectedItem" active-class="blue--text text--accent-4" class="d-flex">
            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" class="px-4 mx-1 mb-0" router to="/gsec">
                  <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
                    G-SEC
                  </v-list-item-title>
                  <v-badge v-if="gsecbag" color="#2962FF" :content="gseclength">
                  </v-badge>
                </v-list-item>
              </template>
              <span>Government Securities</span>
            </v-tooltip>

            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" class="px-4 mx-1 mb-0" router to="/tbill">
                  <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
                    T-BILL
                  </v-list-item-title>
                  <v-badge v-if="tbillbag" color="#2962FF" :content="tbilllength">
                  </v-badge>
                </v-list-item>
              </template>
              <span>Treasury Bills</span>
            </v-tooltip>

            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" class="px-4 mx-1 mb-0" router to="/sdl">
                  <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
                    SDL
                  </v-list-item-title>
                  <v-badge v-if="sdlbag" color="#2962FF" :content="sdllength">
                  </v-badge>
                </v-list-item>
              </template>
              <span>State Development Loans</span>
            </v-tooltip>

            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" class="px-4 mx-1 mb-0" router to="/sgb">
                  <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
                    SGB
                  </v-list-item-title>
                  <v-badge v-if="sgbbag" color="#2962FF" :content="sgblength">
                  </v-badge>
                </v-list-item>
              </template>
              <span>Sovereign Gold Bonds</span>
            </v-tooltip>

            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" class="px-4 mx-1 mb-0" router to="/ipo">
                  <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
                    IPO
                  </v-list-item-title>
                  <v-badge v-if="ipobag" color="#2962FF" :content="ipolength">
                  </v-badge>
                </v-list-item>
              </template>
              <span>Initial Public Offering</span>
            </v-tooltip>

            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" class="px-4 mx-1 mb-0" router to="/sme">
                  <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
                    SME
                  </v-list-item-title>
                  <v-badge v-if="smebag" color="#2962FF" :content="smelength">
                  </v-badge>
                </v-list-item>
              </template>
              <span>Small & Medium Enterprises</span>
            </v-tooltip>

          </v-list-item-group>
        </v-list>
      </v-row>

      <v-tooltip class="d-none d-md-block" bottom color="black" v-if="loginico">
        <template v-slot:activator="{ on, attrs }">
          <v-btn tile @click="goSso()" v-bind="attrs" v-on="on" class="rounded-lg elevation-0 mx-2 d-none d-md-flex" small
            fab color="#F5F5F5">
            <img width="24px" src="@/assets/login.svg">
          </v-btn>
        </template>
        <span>Login in with ZEBULL</span>
      </v-tooltip>

      <v-menu class="d-none d-md-block" v-else rounded="rounded-xl" elevation="0" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn tile v-bind="attrs" v-on="on" class="rounded-lg elevation-0 mx-2 d-none d-md-flex" small fab
            color="#F5F5F5">
            <v-avatar tile v-bind="attrs" v-on="on" color="transparent" class=" d-none d-md-flex" size="36">
              <span class="headline font-weight-bold text-center">{{ cname.slice(0, 1) }}</span>
            </v-avatar>
          </v-btn>
        </template>

        <v-list class="pb-3 pt-0">
          <v-list-item class="pl-4 pr-0 mb-4">
            <v-list-item-content>
              <v-list-item-title class="title font-weight-bold">{{
                  cname
              }}</v-list-item-title>
              <v-list-item-subtitle class="subtitle-1 font-weight-bold">{{
                  cliid
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-btn @click="logout()" class="elevation-0 ml-4" text color="blue"
            style="background-color: aliceblue !important">
            <span class="font-weight-bold">Logout</span>
          </v-btn>
        </v-list>
      </v-menu>
      <!-- </div> -->

      <!-- <div id="mobilemenubar"> -->
      <v-row class="d-flex flex-row-reverse d-md-none">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer right class="d-md-none" v-model="drawer" app hide-overlay>
      <v-tooltip class="d-none d-md-block" bottom color="black" v-if="loginico">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-group  active-class="blue--text text--accent-4">
            <v-list-item v-bind="attrs" v-on="on" @click="goSso()">
              <v-list-item-avatar tile class="rounded-lg">
                <v-avatar tile color="#F5F5F5" size="40">
                  <img width="24px" class="pa-2" src="@/assets/login.svg">
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title><span class="font-weight-bold title">Login</span></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </template>
        <span>Login in with ZEBULL</span>
      </v-tooltip>


      <v-list-item v-else>
        <v-list-item-avatar tile class="rounded-lg">
          <v-avatar tile color="#F5F5F5" size="40">
            <span class="headline font-weight-bold text-center">{{ cname.slice(0, 1) }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title font-weight-bold">{{
              cname
          }}</v-list-item-title>
          <v-list-item-subtitle class="subtitle-1 font-weight-bold">{{
              cliid
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list nav color="transparent">
        <v-list-item-group v-model="selectedItem" active-class="blue--text text--accent-4">
          <v-list-item class="px-4 mx-1 mb-0" router to="/gsec">
            <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
              G-SEC
            </v-list-item-title>
            <v-badge v-if="gsecbag" color="#2962FF" :content="gseclength">
            </v-badge>
          </v-list-item>

          <v-list-item class="px-4 mx-1 mb-0" router to="/tbill">
            <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
              T-BILL
            </v-list-item-title>
            <v-badge v-if="tbillbag" color="#2962FF" :content="tbilllength">
            </v-badge>
          </v-list-item>

          <v-list-item class="px-4 mx-1 mb-0" router to="/sdl">
            <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
              SDL
            </v-list-item-title>
            <v-badge v-if="sdlbag" color="#2962FF" :content="sdllength">
            </v-badge>
          </v-list-item>

          <v-list-item class="px-4 mx-1 mb-0" router to="/sgb">
            <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
              SGB
            </v-list-item-title>
            <v-badge v-if="sgbbag" color="#2962FF" :content="sgblength">
            </v-badge>
          </v-list-item>

          <v-list-item class="px-4 mx-1 mb-0" router to="/ipo">
            <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
              IPO
            </v-list-item-title>
            <v-badge v-if="ipobag" color="#2962FF" :content="ipolength">
            </v-badge>
          </v-list-item>

          <v-list-item class="px-4 mx-1 mb-0" router to="/sme">
            <v-list-item-title class="menusbtn font-weight-bold text-capitalize">
              SME
            </v-list-item-title>
            <v-badge v-if="smebag" color="#2962FF" :content="smelength">
            </v-badge>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div v-if="loginico">
          <!--  -->
        </div>
        <div v-else>
          <v-divider></v-divider>
          <v-list-item>
            <v-btn @click="logout()" block class="elevation-0 text-center" text color="blue"
              style="background-color: aliceblue !important">
              <span class="font-weight-bold">Logout</span>
            </v-btn>
          </v-list-item>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- </div> -->
  </div>
</template>
  


<style>
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #E0E0E0;
  border-radius: 16px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #E0E0E0;
}

.v-menu__content,
.v-dialog,
.crdboxsho {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 7%), 0px 3px 14px 2px rgb(0 0 0 / 6%) !important;
}

.crdboxsho {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 2.5%), 0px 8px 10px 1px rgb(0 0 0 / 1.7%), 0px 3px 14px 2px rgb(0 0 0 / 2%) !important;
}

.v-snack__wrapper {
  border-left: 6px solid orange !important;
}

.investbtn {
  font-size: 18px !important;
  background-color: aliceblue !important;
}

.menusbtn {
  font-size: 18px !important;
  z-index: 1 !important;
}

.titmsg {
  font-size: 18px !important;
}

.ordbokbtn {
  background-color: #F5F5F5 !important;
}

.maindata_per {
  color: #34A853 !important;
}

.v-input input::-webkit-outer-spin-button,
.v-input input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/*  */
.bitstyle .v-input--selection-controls {
  margin-top: 0 !important;
}

.bitstyle .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
  display: none !important;
}

.bitstyle .v-input--dense>.v-input__control>.v-input__slot {
  margin-bottom: 0 !important;
}

.bitstyle .v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.v-autocomplete input {
  font-size: 16px !important;
}

/*  */
.v-data-table .v-data-table__wrapper table thead {
  background-color: #f6f7f9 !important;
}

.v-data-table .v-data-table__wrapper table thead th {
  background-color: #f6f7f9 !important;
  color: #687181 !important;
  font-size: 16px !important;
  text-align: left !important;
  font-weight: 700 !important;
}

.v-data-table-header__sort-badge {
  display: none !important;
}
</style>
  
<script>
import axios from 'axios'
import { apiurl } from '../../apiUrl.js'

export default {
  data: () => ({
    loginico: true,
    drawer: false,
    selectedItem: 0,
    sme: [],
    nodata: false,
    iew: [],
    invesmentprice: "",
    qty: "100",
    cutOff: "",
    errMsg: "",
    dspinfo: [],

    gseclength: "",
    gsecbag: true,
    tbilllength: "",
    tbillbag: true,
    sdllength: "",
    sdlbag: true,
    sgblength: "",
    sgbbag: true,
    ipolength: "",
    ipobag: true,
    smelength: "",
    smebag: true,
    cname: "",
    cliid: "",
    clientId: "",
    session: "",
    sess : '',
  }),
  mounted() {
    var axiosthis = this;
    // var authcode = new URL(window.location.href).searchParams.get("authCode");


    this.cliid = localStorage.getItem("clientid");
    // var secid = localStorage.getItem("session");
    this.cname = localStorage.getItem("cname");
    this.sess = localStorage.getItem("session");
    // console.log("cliixzxzd", this.cliid, this.cname);

    if (this.cliid) {
      // console.log("if", this.loginico)
      axiosthis.loginico = false
      // console.log("if 2", this.loginico)
    }

    var smeconfig = {
      method: "post",
      url: `${apiurl}/getcurrentSMEIPOdetails`,
      headers: {},
    };

    axios(smeconfig)
      .then(function (response) {
        // console.log("sme", response.data);
        axiosthis.sme = response.data.SMEIPO;
        // console.log("sme", axiosthis.sme.length);
        if (response.data.SMEIPO) {
          axiosthis.smelength = axiosthis.sme.length;
        }
        if (response.data.msg == "no IPO found") {
          axiosthis.smebag = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // sme
    var ipoconfig = {
      method: "post",
      url: `${apiurl}/getcurrentIPOdetails`,
      headers: {},
    };

    axios(ipoconfig)
      .then(function (response) {
        axiosthis.ipo = response.data.MainIPO;
        if (response.data.MainIPO) {
          axiosthis.ipolength = axiosthis.ipo.length;
        }

        if (response.data.msg == "no IPO found") {
          axiosthis.ipobag = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // ipo
    var sgbconfig = {
      method: "post",
      url: `${apiurl}/getcurrentSGBdetails`,
      headers: {},
    };

    axios(sgbconfig)
      .then(function (response) {
        axiosthis.sgb = response.data.SGB;
        if (response.data.SGB) {
          axiosthis.sgblength = axiosthis.sgb.length;
        }

        if (response.data.msg == "no SGB found") {
          axiosthis.sgbbag = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // sgb
    var sdlconfig = {
      method: "post",
      url: `${apiurl}/getcurrentNCB_SDLdetails`,
      headers: {},
    };

    axios(sdlconfig)
      .then(function (response) {
        axiosthis.sdl = response.data.NCBSDL;
        if (response.data.NCBSDL) {
          axiosthis.sdllength = axiosthis.sdl.length;
          // console.log("sdl", axiosthis.sdl.length);

        }

        if (response.data.msg == "no SDL found") {
          axiosthis.sdlbag = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // sdl
    var tbillconfig = {
      method: "post",
      url: `${apiurl}/getcurrentNCB_TBilldetails`,
      headers: {},
    };

    axios(tbillconfig)
      .then(function (response) {
        axiosthis.tbill = response.data.NCBTBill;
        // console.log("tbill", axiosthis.tbill.length);
        if (response.data.NCBTBill) {
          axiosthis.tbilllength = axiosthis.tbill.length;
        }

        if (response.data.msg == "no T-Bill found") {
          axiosthis.tbillbag = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // T-bill
    var gsecconfig = {
      method: "post",
      url: `${apiurl}/getcurrentNCB_Gsecdetails`,
      headers: {},
    };

    axios(gsecconfig)
      .then(function (response) {
        axiosthis.gsec = response.data.NCBGsec;
        if (response.data.NCBGsec) {
          axiosthis.gseclength = axiosthis.gsec.length;
        }

        if (response.data.msg == "no G-sec found") {
          axiosthis.gsecbag = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // G-sec
  },
  methods: {
    logout() {
      let data = JSON.stringify({
      "clientid": this.cliid,
      "token": this.sess
    });

    let config = {
      method: 'post',
      url: 'https://rekycbe.mynt.in/autho/logout',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        if (response) {
          localStorage.removeItem("clientid");
      localStorage.removeItem("session");
      localStorage.removeItem("cname");
      this.$router.push("/gsec")
      window.location.reload();
        }
        
      })
      .catch((error) => {
        console.log(error);
  });

      
    },
    goSso() {
            window.location.assign(`https://desk.mynt.in/?url=${window.location.href}`)
        },
  }
}
</script>