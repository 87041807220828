<template>
  <v-app>
    <div>
      <router-view />
    </div>
  </v-app>
</template>

<script>

export default {

  data: () => ({
    //
  }),
};
</script>

<style>
::-webkit-scrollbar {
  width: 6px;
}

#app {
  font-family: 'Rajdhani' !important;
}

.v-application,
.v-application .display-1,
.v-application .headline,
.v-application .title,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .caption {
  font-family: 'Rajdhani' !important;
}
</style>
