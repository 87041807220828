import Vue from 'vue'
import VueRouter from 'vue-router'
import SignUp from '../views/sign-in&up/SignUp.vue'
import SignUpwithotp from '../views/sign-in&up/SignupWithotp.vue'


import LayOut from '../components/Layout/LayOut.vue'

import GsecSrc from '../views/pages/GsecSrc.vue'
import SmeSrc from '../views/pages/SmeSrc.vue'
import IpoPage from '../views/pages/IpoPage.vue'
import TbilSrc from '../views/pages/TbilSrc.vue'
import SdlSrc from '../views/pages/SdlSrc.vue'
import SgbPage from '../views/pages/SgbPage.vue'
import OrderBook from '../views/pages/OrderBook.vue'
import HolDings from '../views/pages/HolDings.vue'
import OrderbookSgb from '../views/pages/OrderbookSgb.vue'
import IpoSmeOb from '../views/pages/OrderbookIposme.vue'
import HoldingIposme from '../views/pages/HoldingIposme.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'signup',
    component: SignUp,
  },
  {
    path: '/signinwithotp',
    name: 'signinwithotp',
    component: SignUpwithotp,
  },

  {
    path: '/',
    redirect: 'gsec',
    name: 'Layout',
    component: LayOut,
    children: [
      {
        path: '/gsec',
        name: 'government sector',
        component: GsecSrc,
      },
      {
        path: '/sme',
        name: 'Small & Medium-sized Enterprises',
        component: SmeSrc,
      },
      {
        path: '/ipo',
        name: 'Initial public offering',
        component: IpoPage,
      },
      {
        path: '/tbill',
        name: 'Treasury bills',
        component: TbilSrc,
      },
      {
        path: '/sdl',
        name: 'State Development Loans',
        component: SdlSrc,
      },
      {
        path: '/sgb',
        name: 'Sovereign Gold Bonds',
        component: SgbPage,
      },
      {
        path: '/OrderBook',
        name: 'OrderBook',
        component: OrderBook,
      },
      {
        path: '/holdings',
        name: 'holdings',
        component: HolDings,
      },
      {
        path: '/orderbookiposme',
        name: 'Order Book of Ipo & Sme',
        component: IpoSmeOb,
      },
      {
        path: '/holdingiposme',
        name: 'holdings of Ipo & Sme',
        component: HoldingIposme,
      },
      {
        path: '/orderbooksgb',
        name: 'orderbook SGB',
        component: OrderbookSgb,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
