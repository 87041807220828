<template>
    <div>
        <v-row no-gutters>
            <v-col cols="9">
                <v-row no-gutters class="mb-4 title  font-weight-bold black--text">
                    <img width="28px" class="mr-2 mb-2 mb-sm-0" src="@/assets/holdingicon.svg" />
                    <span class="d-flex align-end">Holdings </span>
                    <v-card v-for="(item, i) in items" :key="i"
                        class="font-weight-medium subtitle-1 elevation-0 rounded-md ml-2 px-3 d-flex align-center"
                        color="#F5F5F5" v-text="item.text"></v-card>
                </v-row>
            </v-col>
            <v-col cols="3" class="d-flex flex-row-reverse">
                <div>
                    <v-btn to="/OrderBook" text active-class="blue--text text--accent-4"
                        class="ordbokbtn font-weight-bold subtitle-1 elevation-0 mb-2">
                        Order Book <img width="20px" class="ml-1" src="@/assets/ordericon.svg">
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <!-- {{ holdingsdetails }} -->
        <div class="pt-3 pb-4">
            <v-data-table disable-sort :loading="holdingsdetailoader" loading-text="Holdings Loading... Please wait"
                min-width="600" height="70vh" outlined fixed-header :headers="holdingsdetailhead"
                :items="tablefinaldata" class="elevation-1 rounded-lg" :items-per-page="holdingsdetails.length">
                <template v-slot:no-data>
                    <v-container>
                        <v-card height="60vh" class="elevation-0 d-flex justify-space-around align-center">
                            <div class="text-center">
                                <img width="64px" src="@/assets/nodatatable.svg" alt="no orders available">
                                <p class="title font-weight-medium mb-0">no Holdings found</p>
                            </div>
                        </v-card>
                    </v-container>
                </template>
                <template v-slot:[`item.SCRIP_NAME`]="{ item }">
                    <div>
                        <span class="font-weight-medium">
                            {{ item.SCRIP_NAME }} <br> ({{ item.ISSUE_DESC }})
                        </span>
                    </div>
                </template>
                <!-- <template v-slot:[`item.NET`]="{ item }">
                        {{ item.NET.toFixed(2) }}
                </template>
                <template v-slot:[`item.AMOUNT`]="{ item }">
                        {{ item.AMOUNT.toFixed(2) }}
                </template> -->
            </v-data-table>
        </div>

        <v-snackbar style="z-index: 2 !important;" transition="slide-x-reverse-transition" v-model="snackbar"
            :timeout="2500" :value="true" absolute top color="warning" text class="elevaation-0 title" right>
            <v-icon color="warning">mdi-alert-outline</v-icon>
            Session <strong>expired</strong> Kindly Login again.
        </v-snackbar>
    </div>
</template>
  
<script>
import axios from "axios";
import { apiurl } from "../../apiUrl.js";

export default {
    data: () => ({
        snackbar: false,
        holdingsdetails: [],
        tablefinaldata: [],
        holdingsdetailoader: true,
        items: [
            { text: 'G-SEC' },
            { text: 'T-BILL' },
            { text: 'SDL' },
            // { text: 'SGB' },
        ],
    }),

    methods: {
        // 
    },

    mounted() {
        var axiosthis = this;
        this.cliid = localStorage.getItem("clientid");
        this.session = localStorage.getItem("session");
        var data = JSON.stringify({
            "clientId": this.cliid,
        });

        var config = {
            method: 'post',
            url: `${apiurl}/getholdingsdetails`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                // console.log("getholdingsdetails", response.data);
                axiosthis.holdingsdetails = response.data.Data
                          if (response.data.emsg == "invalid token") {

                    axiosthis.snackbar = true;
                    localStorage.removeItem("clientid");
                    localStorage.removeItem("session");
                    localStorage.removeItem("cname");
                    setTimeout(function () {
                        axiosthis.$router.push("/gsec");
                        window.location.reload();
                    }, 2000);
                }
                // console.log(response.data.msg == 'No Data Available');
                // if (response.data.msg == 'No Data Available') {
                //     axiosthis.holdingsdetailoader = false
                //     alert("ghjk")
                    
                // }
                var respdata = response.data.SecurityDetails
                // console.log("respdata", respdata);
                if (axiosthis.holdingsdetails) {
                    for (var i = 0; i < respdata.length; i++) {

                        // console.log("respdata", respdata[i]["ISIN NO."]);
                        if (axiosthis.holdingsdetails[i].ISIN == respdata[i]["ISIN NO."]) {
                            axiosthis.holdingsdetails.push(
                                axiosthis.holdingsdetails[i].NET = Number(axiosthis.holdingsdetails[i].NET).toFixed(0),
                                axiosthis.holdingsdetails[i].Amount = Number(axiosthis.holdingsdetails[i].Amount).toFixed(0),
                                axiosthis.holdingsdetails[i]["CpnFreq"] = respdata[i]["Cpn Freq"],
                                axiosthis.holdingsdetails[i]["ISSUE_DATE"] = respdata[i]["ISSUE_DATE"],
                                axiosthis.holdingsdetails[i]["ISSUE_DESC"] = respdata[i]["ISSUE_DESC"],
                                axiosthis.holdingsdetails[i]["ISSUE_NAME"] = respdata[i]["ISSUE_NAME"],
                                axiosthis.holdingsdetails[i]["Last_ip_TD"] = respdata[i]["Last IP Dt"],
                                axiosthis.holdingsdetails[i]["ltd"] = respdata[i]["Last Traded Date"],
                                axiosthis.holdingsdetails[i]["ltp"] = Number(respdata[i]["Last Traded Price (in Rs.)"]).toFixed(2),
                                axiosthis.holdingsdetails[i]["MAT_DATE"] = respdata[i]["MAT_DATE"],
                                axiosthis.holdingsdetails[i]["Next_ip_Dt"] = respdata[i]["Next IP Dt"],
                                axiosthis.holdingsdetails[i]["SECTYPE"] = respdata[i]["SECTYPE"],
                                axiosthis.holdingsdetails[i]["SECURITY"] = respdata[i]["SECURITY"],
                                axiosthis.holdingsdetails[i]["STATUS"] = respdata[i]["STATUS"],
                            )
                            axiosthis.tablefinaldata.push(axiosthis.holdingsdetails[i])
                            // console.log("tablefinaldata", axiosthis.tablefinaldata);

                        }
                    }

                    // console.log("getholdingsdetails", axiosthis.holdingsdetails);
                    if (axiosthis.holdingsdetails.length > 0) {
                        axiosthis.holdingsdetailoader = false;
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },

    computed: {
        holdingsdetailhead() {
            return [
                { text: "Symbol", value: "SCRIP_NAME", align: "left" },
                { text: "Qty", value: "NET", align: "left" },
                { text: "Amount", value: "AMOUNT", align: "left" },
                { text: "LTP", value: "ltp", align: "left" },
                { text: "Issue date", value: "ISSUE_DATE", align: "left" },
                { text: "Merchant date", value: "MAT_DATE", align: "left" },
                { text: "Status", value: "STATUS", align: "left" },

                // { text: "P&L", value: "MarketCap", align: "right" },

                // { text: "Buy value", value: "Net_Value", align: "right" },
                // { text: "Present value", value: "BuyOrSell", align: "right" },
                // { text: "P&L", value: "Exchange", align: "right" },
                // { text: "P&L change", value: "MarketCap", align: "right" },

                // { text: "Buy value", value: "Net_Value", align: "right" },
                // { text: "Present value", value: "BuyOrSell", align: "right" },
                // { text: "P&L", value: "Exchange", align: "right" },
                // { text: "P&L change", value: "MarketCap", align: "right" },

                // { text: "HOLDDATETIME", value: "HOLDDATETIME" },

                // { text: "BO_ID", value: "BO_ID", sortable: false },
                // { text: "BRBENQTY", value: "BRBENQTY", sortable: false },

                // { text: "COLQTY", value: "COLQTY", sortable: false },
                // { text: "DP_ID", value: "DP_ID", sortable: false },

                // { text: "FreeQTY", value: "FreeQTY", sortable: false },
                // { text: "INSHORT", value: "INSHORT", sortable: false },

                // { text: "LockinQty", value: "LockinQty", sortable: false },

                // { text: "OUTSHORT", value: "OUTSHORT", sortable: false },
                // { text: "PERCENT_HOLD", value: "PERCENT_HOLD", sortable: false },
                // { text: "PLEDGE_QTY", value: "PLEDGE_QTY", sortable: false },

                // { text: "PLedgeQTY", value: "PLedgeQTY", sortable: false },
                // { text: "POA", value: "POA", sortable: false },
                // { text: "SCRIPTYPE", value: "SCRIPTYPE", sortable: false },

                // { text: "SCRIP_CODE", value: "SCRIP_CODE", sortable: false },
                // { text: "SCRIP_VALUE", value: "SCRIP_VALUE", sortable: false },
                // { text: "SOHQTY", value: "SOHQTY", sortable: false },

                // { text: "DEF_ACC", value: "DEF_ACC", sortable: false },
            ];
        },
    },


};
</script>