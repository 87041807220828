<template>
  <div>
    <v-row no-gutters>
      <v-col cols="9">
        <v-row no-gutters class="mb-4 title  font-weight-bold black--text">
          <img width="32px" class="mr-2 mb-2 mb-sm-0" src="@/assets/ordericon.svg" />
          <span class="d-flex align-end">OrderBook </span>
          <v-card v-for="(item, i) in items" :key="i"
            class="font-weight-medium subtitle-1 elevation-0 rounded-md ml-2 px-3 d-flex align-center" color="#F5F5F5">{{
              item.text }}</v-card>
        </v-row>
      </v-col>
      <v-col cols="3" class="d-flex flex-row-reverse">
        <div>
          <!-- <v-btn to="/holdings" text active-class="blue--text text--accent-4"
              class="ordbokbtn font-weight-bold subtitle-1 elevation-0 mb-2">
              Holdings <img width="16px" class="ml-1" src="@/assets/holdingicon.svg">
            </v-btn> -->
        </div>
      </v-col>
    </v-row>

    <div class="mt-4">
      <v-data-table min-width="600" height="60vh" outlined fixed-header :headers="orderbookHeaders"
        :items="orderdatatable" :items-per-page="orderdatatable.length" class="elevation-1 rounded-lg" must-sort
        multi-sort :sort-by="['response_datetime']" :sort-desc="[true]">
        <template v-slot:no-data>
          <v-container>
            <v-card height="60vh" class="elevation-0 d-flex justify-space-around align-center">
              <div class="text-center">
                <img width="64px" src="@/assets/nodatatable.svg" alt="no orders available">
                <p class="title font-weight-medium mb-0">no orders found</p>
              </div>
            </v-card>
          </v-container>
        </template>
        <template v-slot:[`item.orderStatus_response`]="{ item }">
          <v-chip v-if="item.flow" color="success" dark>
            Success
          </v-chip>
          <v-chip v-else color="error" dark>
            Faild
          </v-chip>
        </template>
        <template v-slot:[`item.fail_reason`]="{ item }">
          <span v-if="item.reponse_status == 'failed'">
            {{ item.fail_reason }}
          </span>
          <span v-else>your order placed successfully</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="item.flow" @click="deleteOrder(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="editorder" max-width="520">
      <v-card class="rounded-lg">
        <v-toolbar color="" class="elevation-0" light outlined>
          <v-toolbar-title class="font-weight-bold">
            Remove order
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorder = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="text-center">
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-icon size="38" color="warning">
                mdi-alert-circle-outline
              </v-icon>
              <v-list-item-title class="headline font-weight-bold">Are you sure ?
              </v-list-item-title>
              <v-list-item-subtitle class="subtitle-1 font-weight-medium mt-3">Do you really want to remove this order?
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="mt-4">
            <v-list-item-content class="text-right pt-0">
              <v-list-item-title>
                <v-btn text class="investbtn grey--text font-weight-bold text-capitalize mr-4" @click="editorder = false">
                  <span>
                    cancel
                  </span>
                </v-btn>
                <v-btn color="blue" text class="investbtn font-weight-bold text-capitalize" @click="applyNow()">
                  <span>
                    Delete
                  </span>
                </v-btn>
                <!-- <v-btn color="blue darken-1" text @click="save">
                                    Save
                                </v-btn> -->
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-alert v-if="fundAlart" dense outlined type="info" elevation="0">
            <p class="text-center mb-0 font-weight-medium">Insufficient balance, Add fund ₹ {{ addAmt }} <a
                href="https://fund.zebull.in/"><b>Click here</b></a></p>
          </v-alert>
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar style="z-index: 2 !important;" transition="slide-x-reverse-transition" v-model="snackbar" :timeout="2500"
      :value="true" absolute top color="warning" text class="elevaation-0 title" right>
      <v-icon color="warning">mdi-alert-outline</v-icon>
      Session <strong>expired</strong> Kindly Login again.
    </v-snackbar>
  </div>
</template>
  
<style>
.deletetitle {
  font-size: 28px;
}
</style>
  
<script>
import axios from "axios";
import { apiurl, ledapi } from "../../apiUrl.js";

export default {
  data: () => ({
    snackbar: false,
    editorder: false,
    orderList: [],
    activelistdata: [],
    orderdatatable: [],
    cname: "",
    cliid: "",
    popData: [],
    totalLedger: '',
    button: false,
    fundAlart: false,
    invesmentprice: '',
    session: '',
    qty: 100,
    items: [
      // { text: 'G-SEC' },
      // { text: 'T-BILL' },
      // { text: 'SDL' },
      { text: 'SGB' },
    ],
    orderSucessFetch: '',
    stockDetails: [],
    addAmt: ''
  }),

  methods: {
    getOrder() {
      var axiosthis = this;
      if (axiosthis.cliid !== null && this.session !== null) {
        var config = {
          method: "post",
          url: `${apiurl}/orderbooksgbDetails`,
          headers: {
            'Content-Type': 'application/json',
            'clientid': axiosthis.cliid,
            'Authorization': axiosthis.session
          },
        };
        // console.log("orderbook", config);

        axios(config)
          .then(function (response) {
            // console.log("orderbook", response.data);
            if (response.data.emsg == "invalid token") {

              // console.log("+++++++++++++++++++++++");
              axiosthis.snackbar = true;
              localStorage.removeItem("clientid");
              localStorage.removeItem("session");
              localStorage.removeItem("cname");
              axiosthis.$router.push("/gsec")
              window.location.reload();
            } else {
              if (response.data.msg != 'orders not found') {
                // console.log("---------------");
                axiosthis.orderList = response.data;
                for (var i = 0; i < axiosthis.orderList.length; i++) {
                  // console.log("jiik", axiosthis.orderList[i].reponse_status)
                  if ((axiosthis.orderList[i].orderStatus == "ES") && ((axiosthis.orderList[i].verificationStatus == "S") || (axiosthis.orderList[i].verificationStatus == "P")) && (axiosthis.orderList[i].clearingStatus != 'Rejected')) {
                    axiosthis.orderList[i]['flow'] = true
                  } else {
                    axiosthis.orderList[i]['flow'] = false
                  }
                  axiosthis.orderdatatable.push(axiosthis.orderList[i]);
                  if (axiosthis.orderList[i].reponse_status == "success") {
                    //
                  }
                }
                console.warn("l", axiosthis.orderdatatable)

              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    ledgerCalc() {
      this.invesmentprice = this.stockDetails.cutoffPrice * this.qty
      // console.log("ledgercal", this.totalLedger, this.invesmentprice)
      var totalledger = Number(this.totalLedger)
      // console.log("totalledger", totalledger)
      if ((this.invesmentprice <= 0) || (this.invesmentprice <= 100)) {
        // console.log("as")
        this.button = true
      }

      if (this.invesmentprice < totalledger) {
        // console.log("IF")
        this.button = false
        this.fundAlart = false

      } else {
        // console.log("else")
        this.button = true
        this.fundAlart = true
        this.addAmt = Math.round(this.invesmentprice - this.totalLedger)
      }
    },
    applyNow() {
      // var ncb = this.popData;
      // console.log("ncb", ncb, this.stockDetails);

      var axiosthis = this;
      var data = JSON.stringify({
        requestfor: "REMOVE",
        symbol: this.popData.symbol,
        bidQuantity: this.popData.quantity,
        investmentValue: this.popData.investmentValue,
        price: this.popData.price,
        "orderNumber": this.popData.orderNumber,
        "clientApplicationNumber": this.popData.clientApplicationNumber,
      });
      // console.log("ncb", data);

      var config = {
        method: "post",
        url: `${apiurl}/addSGBtoPortfolio`,
        headers: {
          'Content-Type': 'application/json',
          'clientid': axiosthis.cliid,
          'Authorization': axiosthis.session
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log("order res", response.data);
          axiosthis.orderResponce = response.data;
          window.location.reload();

          if (response.data.emsg == "invalid token") {

            axiosthis.snackbar = true;
            console.warn("Hs")
            localStorage.removeItem("clientid");
            localStorage.removeItem("session");
            localStorage.removeItem("cname");

            axiosthis.$router.push("/gsec")

            window.location.reload();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteOrder(item) {
      var axiosthis = this
      this.editorder = true;
      // console.log("invesmentprice", typeof (this.invesmentprice));

      // console.log("it", item);
      this.popData = item
      for (var i = 0; i < this.popData.length; i++) {
        // console.log("it", this.popData);
      }
      let dataM = JSON.stringify({
        'clientid': axiosthis.clientId
      });

      var config = {
        method: "post",
        url: `${ledapi}/all_ledger_balance`,
        headers: {
          'Content-Type': 'application/json',
          'clientid': axiosthis.clientId,
          'Authorization': axiosthis.session
          // },
        },
        data: dataM
      };
      // console.log("config", config);

      axios(config)
        .then(function (response) {
          // console.log(response);

          if (!response.data.total) {
            //           if (response.data.emsg == "invalid token") {

            axiosthis.snackbar = true;
            console.warn("Hs")
            localStorage.removeItem("clientid");
            localStorage.removeItem("session");
            localStorage.removeItem("cname");

            axiosthis.$router.push("/gsec")

            window.location.reload();
            // }
          } else {

            if (response.data.total) {
              axiosthis.totalLedger = response.data.total;

              if (axiosthis.totalLedger < axiosthis.invesmentprice) {
                axiosthis.button = true
                axiosthis.fundAlart = true
                axiosthis.ledgerCalc()
              }
            }
            //------------------------------------------ symbol

          }

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getColor() {
      // if ((reponse_status == "success") || (reponse_status == "Entry success")) return "#34A853";
      // else
      return "#E12525";
    },
  },

  mounted() {
    this.cliid = localStorage.getItem("clientid");
    this.session = localStorage.getItem("session");
    this.cname = localStorage.getItem("cname");
    this.getOrder()
    // console.log("orderbook", this.session);

  },

  computed: {
    orderbookHeaders() {
      return [
        { text: "Symbol", value: "symbol", sortable: false },
        { text: "Order Number", value: "orderNumber", sortable: false },
        { text: "Datetime", value: "response_datetime" },
        // { text: "Application Number", value: "applicationNumber" },
        { text: "Amount", value: "investmentValue", sortable: false },
        { text: "Reason", value: "fail_reason", sortable: false },
        { text: "Status", value: "orderStatus_response", sortable: false },
        { text: "", value: "actions", sortable: false },
      ];
    },
  },
};
</script>
  
<style>
.activeliststy {
  max-height: 60vh !important;
  overflow-y: scroll !important;
}
</style>