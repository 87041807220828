<template>
    <div>
        <v-row no-gutters class="mb-4 title  font-weight-bold black--text">
            <!-- <img width="32px" class="mr-2 mb-2 mb-sm-0" src="@/assets/ordericon.svg" /> -->
            <span class="d-flex align-end">Holdings </span>
            <v-card v-for="(item, i) in items" :key="i"
                class="font-weight-medium subtitle-1 elevation-0 rounded-md ml-2 px-3 d-flex align-center"
                color="#F5F5F5" v-text="item.text"></v-card>
        </v-row>
    </div>
</template>
  
<script>
//   import axios from "axios";
//   import { apiurl } from "../../apiUrl.js";

export default {
    data: () => ({

        items: [
            { text: 'IPO' },
            { text: 'SME' },
        ],
    }),

    methods: {
        // 
    },

    mounted() {
        //  
    },


};
</script>