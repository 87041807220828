<template>
  <div>
    <v-row no-gutters>
      <v-col cols="6">
        <v-row no-gutters>
          <img width="40px" class="mr-2" src="@/assets/tbill.svg">
          <span class="title font-weight-bold black--text d-flex align-end">T-BILL</span>
        </v-row>
      </v-col>
      <v-col cols="6" class="d-flex flex-row-reverse">
        <div>
          <v-tooltip bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="orderbtnvis" v-bind="attrs" v-on="on" to="/OrderBook" text
                active-class="blue--text text--accent-4"
                class="ordbokbtn font-weight-bold subtitle-1 elevation-0 mb-2 mr-4">
                <span class="d-none d-md-block">Order Book</span>
                <img width="20px" class="ml-md-1" src="@/assets/ordericon.svg">
              </v-btn>
            </template>
            <v-card class="d-block d-md-none elevation-0 px-4 py-1" color="black">
              <span class="white--text">Order Book</span>
            </v-card>
          </v-tooltip>

          <v-tooltip bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="orderbtnvis" v-bind="attrs" v-on="on" to="/holdings" text
                active-class="blue--text text--accent-4"
                class="ordbokbtn font-weight-bold subtitle-1 elevation-0 mb-2 mr-4">
                <span class="d-none d-md-block">Holdings</span>
                <img width="16px" class="ml-md-1" src="@/assets/holdingicon.svg">
              </v-btn>
            </template>
            <v-card class="d-block d-md-none elevation-0 px-4 py-1" color="black">
              <span class="white--text">Holdings</span>
            </v-card>
          </v-tooltip>

          <v-tooltip v-if="$vuetify.breakpoint.mdAndUp" bottom color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="viewtypebtn" v-bind="attrs" v-on="on" @click="scriptsviews = !scriptsviews"
                active-class="blue--text text--accent-4" class="ordbokbtn elevation-0 px-0 mb-2">
                <v-icon v-if="scriptsviews == false">mdi-view-day</v-icon>
                <v-icon v-else>mdi-view-dashboard</v-icon>
              </v-btn>
            </template>
            <span v-if="scriptsviews == false">List view</span>
            <span v-else>Card view</span>
          </v-tooltip>

          <!-- <v-btn to="/OrderBook" text active-class="blue--text text--accent-4"
            class="ordbokbtn font-weight-bold subtitle-1 elevation-0 mb-2 mr-4">
            Order Book <img width="20px" class="ml-1" src="@/assets/ordericon.svg">
          </v-btn>
          <v-btn to="/holdings" text active-class="blue--text text--accent-4"
            class="ordbokbtn font-weight-bold subtitle-1 elevation-0 mb-2">
            Holdings <img width="16px" class="ml-1" src="@/assets/holdingicon.svg">
          </v-btn> -->
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <p class="titmsg font-weight-medium">
          Treasury bills
          Offer short-term investment opportunities, often up to one year.
        </p>
        <v-row v-if="scriptsviews">
          <v-col cols="12" v-for="(tbilldata, i) in tbill" :key="i" class="shrink pb-0">
            <div>
              <v-card @click="inforsrc(tbilldata)" class="elevation-0 rounded-lg" width="100%" color="#F9FBFC">
                <v-list-item>
                  <!-- <v-card width="100%" class="elevation-0" color="transparent"> -->
                  <v-list-item-content>
                    <v-list-item-title class="title font-weight-bold">{{ tbilldata.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-bold">{{ tbilldata.symbol }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">BID START
                    </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold">{{ startDate }} <span
                        style="color:rgba(0, 0, 0, 0.6); font-size: 14px;" class="font-weight-bold">({{
              tbilldata.dailyStartTime
            }})</span>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">BID END
                    </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold">{{ endDate }} <span
                        style="color:rgba(0, 0, 0, 0.6); font-size: 14px;" class="font-weight-bold">({{
              tbilldata.dailyEndTime
            }})</span>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-btn @click="investNow(tbilldata)" color="blue" dense text
                    class="font-weight-bold subtitle-1 elevation-0">
                    INVEST NOW
                  </v-btn>
                </v-list-item>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <!-- ---------------------------- -->
        <v-row v-else>
          <v-col cols="12" sm="6" md="4" xl="3" v-for="(tbilldata, i) in tbill" :key="i" class="shrink">
            <div>
              <v-card @click="inforsrc(tbilldata)" class="elevation-0 rounded-lg py-2 pb-0 mr-4" width="100%"
                color="#F9FBFC">
                <v-list-item>
                  <v-card width="100%" class="elevation-0" color="transparent">
                    <v-list-item-content>
                      <v-list-item-title class="title font-weight-bold">{{ tbilldata.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-bold">{{ tbilldata.symbol }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-card>
                  <!-- <v-card width="20%" class="elevation-0" color="transparent">
                    <v-list-item-content class="text-right">
                      <v-list-item-title><span class="maindata_per title font-weight-bold">0 %</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-card> -->
                </v-list-item>

                <v-divider class="mx-4"></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold">BID START
                    </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold">{{ startDate }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-bold">{{ tbilldata.dailyStartTime }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content class="text-right">
                    <v-list-item-subtitle class="font-weight-bold">BID END
                    </v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold">{{ endDate }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-bold">{{ tbilldata.dailyEndTime }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <p class="mb-0 pb-2 text-center">
                  <v-btn @click="investNow(tbilldata)" color="blue" dense text
                    class="font-weight-bold subtitle-1 elevation-0">
                    INVEST NOW
                  </v-btn>
                </p>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-card v-if="nodataimg" class="elevation-0 text-center mt-16 px-4">
          <img width="64px" src="@/assets/finding no.svg" alt="finding no data">
          <p class="title font-weight-medium">{{ errMsg }}</p>
        </v-card>
      </v-col>

      <v-dialog v-model="diavlog" class="rounded-lg" max-width="520">
        <v-card v-if="ordsrcto" class="rounded-lg">
          <v-toolbar color="" class="elevation-0" light outlined>
            <v-toolbar-title class="font-weight-bold">
              Order
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="diavlog = false, qty = 100">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div class="pb-4 px-8">
            <v-alert class="mb-0 mt-3" dense v-if="marketstatus" outlined type="warning" elevation="0">
              <p class="text-center mb-0 font-weight-medium">Market is not open</p>
            </v-alert>
            <v-list-item class="px-0">
              <v-list-item-content>
                <v-list-item-title class="title font-weight-bold">{{ tbilliew.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold">{{ tbilliew.symbol }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <!-- <v-list-item-content class="text-right">
                            <v-list-item-title><span class="maindata_per title font-weight-bold">0 %</span>
                            </v-list-item-title>
                          </v-list-item-content> -->
            </v-list-item>

            <v-text-field type="number" v-model="qty" step="100" :max="maxQuantity" :min="minQuantity"
              @keyup="ledgerCalc()" class="ma-0" label="Units" placeholder="10000" outlined :hint="'Units limit' + minQuantity + '-' + maxQuantity
              " persistent-hint>
            </v-text-field>
            <p><span>Ledger balance:</span> {{ totalLedger }}</p>
            <v-list-item class="px-0">
              <v-list-item-content class="pt-0">
                <v-list-item-title>
                  <p class="subtitle-1 font-weight-medium mb-0">
                    Price :
                    <span class="font-weight-bold title">{{
              tbilliew.cutoffPrice
            }}</span>
                  </p>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="text-right pt-0">
                <v-list-item-title>
                  <v-btn :disabled="button" text class="investbtn font-weight-medium text-capitalize" color="blue"
                    @click="applyNow()">
                    <p class="font-weight-bold title mb-0">
                      Invest ₹
                      <span> {{ invesmentprice }}</span>
                    </p>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-alert v-if="fundAlart" dense outlined type="info" elevation="0">
              <p class="text-center mb-0 font-weight-medium">Insufficient balance, Add fund ₹ {{ addAmt }}
                <a href="https://fund.zebull.in/"><b>Click here</b></a>
              </p>
            </v-alert>
          </div>
        </v-card>
        <v-card v-else class="rounded-lg">
          <v-toolbar color="" class="elevation-0" light outlined>
            <v-toolbar-title class="font-weight-bold">
              Order details
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="diavlog = false, ordsrcto = true">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear indeterminate color="blue" v-if="orderLoader"></v-progress-linear>
          <div class="pb-4 px-8">
            <v-list-item class="px-0">
              <v-list-item-content>
                <v-list-item-title class="title font-weight-bold">{{ tbilliew.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-subtitle class="font-weight-medium mb-3">
                    OrderNumber
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="font-weight-medium">
                    TotalAmountPayable
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium mb-3">
                    : <span>{{ orderResponce.orderNumber }}</span>
                  </v-list-item-title>
                  <v-list-item-title class="font-weight-medium">
                    : ₹
                    <span>{{
                      orderResponce.totalAmountPayable
                    }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <p class="mb-1">
                Reason : <span>
                  {{ orderResponce.reason }}
                </span>

              </p>

              <span v-if="orderResponce.status == 'failed'" class="red--text font-weight-bold title">
                {{ orderResponce.status }}
              </span>
              <span v-else class="green--text font-weight-bold title">{{ orderResponce.status }}</span>
            </div>
        </v-card>
      </v-dialog>

      <v-col class="shrink pl-3 d-none d-md-block">
        <v-expand-x-transition>
          <v-card class="elevation-0 rounded-lg pa-3" v-show="expand" width="300" color="#E3F2FD">
            <v-row no-gutters>
              <v-col cols="10"><span class="title font-weight-bold black--text">{{
              dspinfo.name
            }}</span></v-col>
              <v-col cols="2" class="d-flex flex-row-reverse">
                <v-btn icon @click="expand = false" class="font-weight-bold subtitle-1 mb-2">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="pb-2"></v-divider>
            <v-row v-for="(item, i) in tbilldspino" :key="i" no-gutters>
              <v-col cols="6">
                <v-list-item-title class="subtitle-1 font-weight-bold">{{ item.text }}
                </v-list-item-title>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-list-item-subtitle class="subtitle-1 font-weight-medium">{{ item.value }}
                </v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-x-transition>
      </v-col>
    </v-row>

    <div class="d-md-none">
      <v-bottom-sheet class="d-md-none" hide-overlay v-model="sheet" inset>
        <v-sheet color="#F9FBFC" class="rounded-lg d-md-none" height="100%">
          <v-card class="elevation-0 pa-4 px-6" width="100%" color="transparent">
            <v-row no-gutters>
              <v-col cols="10"><span class="title font-weight-bold black--text">{{
              dspinfo.name
            }}</span></v-col>
              <v-col cols="2" class="d-flex flex-row-reverse">
                <v-btn icon @click="sheet = false" class="font-weight-bold subtitle-1 mb-2">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="pb-2"></v-divider>
            <v-row v-for="(item, i) in tbilldspino" :key="i" no-gutters>
              <v-col cols="6">
                <v-list-item-title class="subtitle-1 font-weight-bold">{{ item.text }}
                </v-list-item-title>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-list-item-subtitle class="subtitle-1 font-weight-medium">{{ item.value }}
                </v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <v-snackbar transition="slide-x-reverse-transition" v-model="snackbar" :timeout="2500" :value="true" absolute top
      color="warning" text class="elevaation-0 title" right>
      <v-icon color="warning">mdi-alert-outline</v-icon>
      Session <strong>expired</strong> Kindly Login again.
    </v-snackbar>
  </div>
</template>

<style>
/*  */
</style>

<script>
import axios from "axios";
import { apiurl, ledapi } from "../../apiUrl.js";

export default {
  data: () => ({
    scriptsviews: false,
    viewtypebtn: true,
    diavlog: false,
    ordsrcto: true,
    marketstatus: false,
    snackbar: false,
    expand: false,
    sheet: false,
    orderbtnvis: true,
    tbill: [],
    nodata: false,
    nodataimg: false,
    tbilliew: [],
    invesmentprice: "",
    qty: "100",
    cutOff: "",
    errMsg: "",
    dspinfo: [],
    clientId: "",
    session: "",
    minQuantity: "",
    maxQuantity: "",
    totalLedger: 0,
    endDate: "",
    startDate: "",
    orderResponce: [],
    fundAlart: false,
    button: true,

    tbillStartTime: 0,
    tbillEndTime: 0,
    orderLoader: true,
    errorres: '',
  }),

  methods: {
    ledgerCalc() {
      this.invesmentprice = this.tbilliew.cutoffPrice * this.qty
      // console.log("ledgercal", this.totalLedger, this.invesmentprice)
      var totalledger = Number(this.totalLedger)
      // console.log("totalledger", totalledger)
      var myDate = new Date();
      var hrs = myDate.getHours();
      if (hrs >= this.tbillStartTime && hrs <= this.tbillEndTime) {
        if ((this.invesmentprice <= 0) || (this.invesmentprice <= 100)) {
          // console.log("as")
          this.button = true
        }

        if (this.invesmentprice < totalledger) {
          // console.log("IF")
          this.button = false
          this.fundAlart = false

        } else {
          // console.log("else")
          this.button = true
          this.fundAlart = true
          this.addAmt = Math.round(this.invesmentprice - this.totalLedger)
        }
      }
    },
    inforsrc(det) {
      this.expand = true;
      this.sheet = true;
      this.dspinfo = det;
      // console.log("indata", det);
    },

    investNow(data) {
      var axiosthis = this
      if ((axiosthis.clientId == null) && (axiosthis.session == null)) {
        axiosthis.diavlog = false;
        window.location.assign(`https://desk.mynt.in/?url=${window.location.href}`)
        // console.log("none")
      } else {
        axiosthis.diavlog = true;
        axiosthis.ordsrcto = true;

        let dataM = JSON.stringify({
          'clientid': axiosthis.clientId
        });

        var config = {
          method: "post",
          url: `${ledapi}/all_ledger_balance`,
          headers: {
            'Content-Type': 'application/json',
            'clientid': axiosthis.clientId,
            'Authorization': axiosthis.session
            // },
          },
          data: dataM
        };

        axios(config)
          .then(function (response) {
            if (response.data.total) {
              axiosthis.totalLedger = response.data.total;

              if (axiosthis.totalLedger < axiosthis.invesmentprice) {
                axiosthis.button = true
                axiosthis.fundAlart = true
                axiosthis.ledgerCalc()
              } else {
                axiosthis.button = false
                axiosthis.fundAlart = false
                axiosthis.ledgerCalc()
              }




              //       var myDate = new Date();
              // var hrs = myDate.getHours();
              // if (hrs >= this.tbillStartTime && hrs <= this.tbillEndTime) {
              //   this.button = false;
              //   this.marketstatus = false;
              // }
              // else if((axiosthis.totalLedger)&& !(hrs >= this.tbillStartTime && hrs <= this.tbillEndTime)) {
              //   this.button = true;
              //   this.marketstatus = true;
              // }
            }
            if (response.data.emsg == "invalid token") {

              axiosthis.diavlog = false;
              axiosthis.snackbar = true;
              console.warn("Hs")
              localStorage.removeItem("clientid");
              localStorage.removeItem("session");
              localStorage.removeItem("cname");
              setTimeout(function () {
                axiosthis.$router.push("/gsec");
                window.location.reload();
              }, 2000);
            } else if (response.data.msg == "Token is Invalid.") {
              axiosthis.diavlog = false;
              axiosthis.snackbar = true;
              console.warn("Hs")
              localStorage.removeItem("clientid");
              localStorage.removeItem("session");
              localStorage.removeItem("cname");
              setTimeout(function () {
                axiosthis.$router.push("/gsec");
                window.location.reload();
              }, 2000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        // console.log("II", data);
        this.tbilliew = data;
        this.cutOff = data.cutoffPrice;
        this.invesmentprice = data.cutoffPrice * this.qty;
        this.minQuantity = data.minBidQuantity / 100;
        this.maxQuantity = data.maxQuantity / 100;
        // console.log("tbilliew", data);
      }
    },

    applyNow() {
      this.diavlog = true;
      this.ordsrcto = false;
      var ncb = this.tbilliew;
      // console.log("ncb", ncb, this.clientId);
      this.errorres = ''
      var axiosthis = this;
      var data = JSON.stringify({
        requestfor: "BUY",
        symbol: ncb.symbol,
        investmentValue: this.invesmentprice,
        price: this.cutOff,
      });
      // console.log("ncb", data);

      var config = {
        method: "post",
        url: `${apiurl}/addNCBtoPortfolio`,
        headers: {
          'Content-Type': 'application/json',
          'clientid': axiosthis.clientId,
          'Authorization': axiosthis.session
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          axiosthis.orderLoader = false
          // console.log("order res", response.data);
          axiosthis.orderResponce = response.data;
          if (response.data.emsg == 'Authorization Required' || response.data.emsg == "token expired" || response.data.emsg == "invalid token") {
            window.location.assign(`https://desk.mynt.in/?url=${window.location.href}`)
          }
          if (response.data.data) {
            axiosthis.errorres = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error);
          axiosthis.orderLoader = false
        });
    },
  },

  computed: {
    tbilldspino() {
      return [
        { text: "symbol", value: this.dspinfo.symbol },
        { text: "isin", value: this.dspinfo.isin },
        // { text: "issueType", value: this.dspinfo.issueType },
        { text: "issueSize", value: this.dspinfo.issueSize },
        { text: "faceValue", value: this.dspinfo.faceValue },
        { text: "maxPrice", value: this.dspinfo.maxPrice },
        { text: "minPrice", value: this.dspinfo.minPrice },
        { text: "minBidQuantity", value: this.dspinfo.minBidQuantity },
        { text: "cutoffPrice", value: this.dspinfo.cutoffPrice },
        { text: "lotSize", value: this.dspinfo.lotSize },
        { text: "index", value: this.dspinfo.index },
      ];
    },
  },

  mounted() {
    this.clientId = localStorage.getItem("clientid");
    this.session = localStorage.getItem("session");
    if (this.clientId == null && this.session == null) {
      this.orderbtnvis = false;
    }

    var axiosthis = this;
    var conffig = {
      method: "post",
      url: `${apiurl}/getcurrentNCB_TBilldetails`,
      headers: {},
    };

    axios(conffig)
      .then(function (response) {
        // console.log("tbill", response.data);
        axiosthis.tbill = response.data.NCBTBill;
        // console.log("tbill", response.data.NCBTBill[0].dailyEndTime);

        if (response.data.NCBTBill) {
          axiosthis.tbillStartTime = response.data.NCBTBill[0].dailyStartTime.slice(0, 2);
          axiosthis.tbillEndTime = response.data.NCBTBill[0].dailyEndTime.slice(0, 2);
          axiosthis.tbillStartTime = Number(axiosthis.tbillStartTime)
          axiosthis.tbillEndTime = Number(axiosthis.tbillEndTime - 1)
          for (var i = 0; i < response.data.NCBTBill.length; i++) {
            // console.log("date for", response.data.NCBTBill[i].biddingEndDate);
            var endDate = new Date(response.data.NCBTBill[i].biddingEndDate);
            var startDate = response.data.NCBTBill[i].biddingStartDate;

            let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
              endDate
            );
            let mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(
              endDate
            );
            let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
              endDate
            );
            // console.log(`${da}-${mo}-${ye}`);
            axiosthis.endDate = `${da}-${mo}-${ye}`;
            axiosthis.startDate = startDate;
          }
        }

        if (response.data.msg == "no T-Bill found") {
          axiosthis.errMsg = "Finding no T-Bill";
          axiosthis.nodataimg = true;
          axiosthis.viewtypebtn = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  created() {
    this.clientId = localStorage.getItem("clientid")
    this.session = localStorage.getItem("session")
    // console.log("responclientIdse", this.clientId, this.session);

    if ((this.clientId == null) && (this.session == null)) {
      this.orderbtnvis = false;
    }
  }
};
</script>