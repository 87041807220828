<template>
  <div>
    <v-row no-gutters class="mb-4 title  font-weight-bold black--text">
      <img width="32px" class="mr-2 mb-2 mb-sm-0" src="@/assets/ordericon.svg" />
      <span class="d-flex align-end">OrderBook </span>
      <v-card v-for="(item, i) in items" :key="i"
        class="font-weight-medium subtitle-1 elevation-0 rounded-md ml-2 px-3 d-flex align-center" color="#F5F5F5">{{
          item.text }}</v-card>
    </v-row>

    <div class="mt-4">
      <v-data-table min-width="600" height="68vh" outlined fixed-header :headers="orderbookHeaders" :items="orderList"
        :items-per-page="orderList.length" class="elevation-1 rounded-lg" must-sort multi-sort
        :sort-by="['response_datetime']" :sort-desc="[true]">
        <template v-slot:no-data>
          <v-container>
            <v-card height="60vh" class="elevation-0 d-flex justify-space-around align-center">
              <div class="text-center">
                <img width="64px" src="@/assets/nodatatable.svg" alt="no orders available">
                <p class="title font-weight-medium mb-0">no orders available</p>
              </div>
            </v-card>
          </v-container>
        </template>
        <template v-slot:[`item.reponse_status`]="{ item }">
          <v-chip v-if="orderSucessFetch" :color="getColor(orderSucessFetch)" dark>
            {{ orderSucessFetch }}
          </v-chip>
          <v-chip v-else :color="getColor(item.reponse_status)" dark>
            {{ item.reponse_status }}
          </v-chip>
        </template>
        <template v-slot:[`item.fail_reason`]="{ item }">
          <span v-if="item.reponse_status == 'failed'">
            {{ item.fail_reason }}
          </span>
          <span v-else>your order placed successfully</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="item.reponse_status == 'success'" small class="mr-2" @click="editItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:[`item.response_datetime`]="{ item }">
          {{ item.response_datetime.slice(0, -7) }}
        </template>
      </v-data-table>
    </div>

    <!-- <div class="mt-4">
      <p class="title font-weight-bold black--text mb-1">Orders</p>
      <v-data-table min-width="600" height="60vh" outlined fixed-header :headers="orderbookHeaders" :items="Allotments"
        :items-per-page="50" class="elevation-1 rounded-lg" must-sort multi-sort :sort-by="['response_datetime']"
        :sort-desc="[true]">
        <template v-slot:no-data>
          <v-container>
            <v-card height="60vh" class="elevation-0 d-flex justify-space-around align-center">
              <div class="text-center">
                <img width="64px" src="@/assets/nodatatable.svg" alt="no orders available">
                <p class="title font-weight-medium mb-0">no orders found</p>
              </div>
            </v-card>
          </v-container>
        </template>
        <template v-slot:[`item.reponse_status`]="{ item }">
          <v-chip v-if="orderSucessFetch" :color="getColor(orderSucessFetch)" dark>
            {{ orderSucessFetch }}
          </v-chip>
          <v-chip v-else :color="getColor(item.reponse_status)" dark>
            {{ item.reponse_status }}
          </v-chip>
        </template>
        <template v-slot:[`item.fail_reason`]="{ item }">
          <span v-if="item.reponse_status == 'failed'">
            {{ item.fail_reason }}
          </span>
          <span v-else>your order placed successfully</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="item.reponse_status == 'success'" small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:[`item.response_datetime`]="{ item }">
          {{ item.response_datetime.slice(0, -7) }}
        </template>
      </v-data-table>
    </div> -->

    <v-dialog v-model="editorder" max-width="520">
      <v-card class="rounded-lg">
        <v-toolbar color="" class="elevation-0" light outlined>
          <v-toolbar-title class="font-weight-bold">
            Edit order
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editorder = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pb-4 px-8">
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title class="title font-weight-bold">{{ popData.symbol }}
              </v-list-item-title>
              <!-- <v-list-item-subtitle class="font-weight-bold"
                >df
              </v-list-item-subtitle> -->
            </v-list-item-content>

            <v-list-item-content class="text-right">
              <!-- <v-list-item-title><span style="color: #34a853" class="title font-weight-bold">0 %</span>
              </v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>

          <v-text-field type="number" v-model="qty" step="100" :max="100" :min="200000"
            @keyup="invesmentprice = tbilliew * qty" class="ma-0" label="Units" placeholder="10000" outlined
            :hint="'Units limit'" persistent-hint>
          </v-text-field>
          <p><span>Total Ledger: </span>{{ totalLedger }}</p>
          <v-list-item class="px-0">
            <v-list-item-content class="pt-0">
              <v-list-item-title>
                <p class="subtitle-1 font-weight-medium mb-0">
                  Price :
                  <span class="font-weight-bold title">104</span>
                </p>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content class="text-right pt-0">
              <v-list-item-title>
                <v-btn text class="investbtn font-weight-medium text-capitalize" color="blue" @click="applyNow()">
                  <p class="font-weight-bold title mb-0">
                    Invest ₹
                    <span> 4567</span>
                  </p>
                </v-btn>
                <!-- <v-btn color="blue darken-1" text @click="save">
                                    Save
                                </v-btn> -->
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-alert v-if="fundAlart" dense outlined type="info" elevation="0">
            <p class="text-center mb-0 font-weight-medium">Insufficient balance, Add fund ₹ {{ invesmentprice }} <a
                href="https://fund.zebull.in/"><b>Click here</b></a></p>
          </v-alert>
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar style="z-index: 2 !important;" transition="slide-x-reverse-transition" v-model="snackbar" :timeout="2500"
      :value="true" absolute top color="warning" text class="elevaation-0 title" right>
      <v-icon color="warning">mdi-alert-outline</v-icon>
      Session <strong>expired</strong> Kindly Login again.
    </v-snackbar>
  </div>
</template>

<style>
/*  */
</style>

<script>
import axios from "axios";
import { apiurl, ledapi } from "../../apiUrl.js";

export default {
  data: () => ({
    snackbar: false,
    editorder: false,
    orderList: [],
    cname: "",
    cliid: "",
    popData: [],
    totalLedger: '',
    button: false,
    fundAlart: false,
    invesmentprice: '',
    session: '',
    qty: 100,
    items: [
      { text: 'IPO' },
      { text: 'SME' },
    ],
    orderSucessFetch: ''
  }),

  methods: {
    editItem(item) {
      var axiosthis = this
      this.editorder = true;
      // console.log("it", item);
      this.popData = item
      let dataM = JSON.stringify({
        'clientid': axiosthis.clientId
      });

      var config = {
        method: "post",
        url: `${ledapi}/all_ledger_balance`,
        headers: {
          'Content-Type': 'application/json',
          'clientid': axiosthis.clientId,
          'Authorization': axiosthis.session
          // },
        },
        data: dataM
      };
      // console.log("config", config);

      axios(config)
        .then(function (response) {
          // console.log(response);

          if (response.data.total) {
            axiosthis.totalLedger = response.data.total;
            if (axiosthis.totalLedger < axiosthis.invesmentprice) {
              axiosthis.button = true
              axiosthis.fundAlart = true
            }
          }
          if (response.data.emsg == "invalid token") {

            axiosthis.ordsrcto = false;
            axiosthis.snackbar = true;
            console.warn("Hs")
            localStorage.removeItem("clientid");
            localStorage.removeItem("session");
            localStorage.removeItem("cname");
            setTimeout(function () {
              axiosthis.$router.push("/gsec");
              window.location.reload();
            }, 2000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getColor(reponse_status) {
      if ((reponse_status == "success") || (reponse_status == "Entry success")) return "#34A853";
      else return "#E12525";
    },
  },

  mounted() {
    var axiosthis = this;
    this.cliid = localStorage.getItem("clientid");
    this.session = localStorage.getItem("session");
    this.cname = localStorage.getItem("cname");
    if (axiosthis.cliid !== null && this.session !== null) {
      var config = {
        method: "post",
        url: `${apiurl}/orderbookIPODetails`,
        headers: {
          'Content-Type': 'application/json',
          'clientid': axiosthis.cliid,
          'Authorization': axiosthis.session
        },
      };

      axios(config)
        .then(function (response) {
          // console.log("orderbook", response.data);
          if (response.data.emsg == "invalid token") {
            // console.log("+++++++++++++++++++++++");
            axiosthis.snackbar = true;
            localStorage.removeItem("clientid");
            localStorage.removeItem("session");
            localStorage.removeItem("cname");
            setTimeout(function () {
              axiosthis.$router.push("/gsec");
              window.location.reload();
            }, 2000);
          } else {
            if (response.data.msg != 'orders not found') {
              // console.log("---------------",response.data);
              axiosthis.orderList = response.data;
              for (var i = 0; i < axiosthis.orderList.length; i++) {
                // console.log("res  ststus", axiosthis.orderList[i]);
                if (axiosthis.orderList[i].reponse_status == "success") {

                  var data = JSON.stringify({
                    'symbol': axiosthis.orderList[i].symbol,
                    'applicationNumber': axiosthis.orderList[i].applicationNumber,
                    'clientApplicationNumber': axiosthis.orderList[i].clientApplicationNumber,
                    // "type": axiosthis.orderList[i].type
                  });
                  var configs = {
                    method: "post",
                    url: `${apiurl}/fetchIPODetail`,
                    headers: {
                      'Content-Type': 'application/json',
                      'clientid': axiosthis.cliid,
                      'Authorization': axiosthis.session

                    },
                    data: data,
                  };

                  // console.log("jiik", data, configs);

                  axios(configs)
                    .then(function (response) {
                      // console.log("book res", response);
                      if (response.data.emsg == "invalid token") {

                        axiosthis.snackbar = true;
                        console.warn("Hs")
                        localStorage.removeItem("clientid");
                        localStorage.removeItem("session");
                        localStorage.removeItem("cname");
                        setTimeout(function () {
                          axiosthis.$router.push("/gsec");
                          window.location.reload();
                        }, 2000);
                      } else if (!response.data.msg) {
                        // console.log("book res", response.data);
                        axiosthis.orderSucessFetch = response.data[0].orderStatus_response
                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                }
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },

  computed: {
    orderbookHeaders() {
      return [
        { text: "symbol", value: "symbol", sortable: false },
        { text: "Order Number", value: "clientApplicationNumber", sortable: false },
        { text: "Datetime", value: "response_datetime" },
        // { text: "Application Number", value: "applicationNumber" },
        { text: "Upi id", value: "upi", sortable: false },
        { text: "Reason", value: "fail_reason", sortable: false },
        { text: "Status", value: "reponse_status", sortable: false },
        // { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
};
</script>